import React, { useContext, useEffect, useState } from 'react';
import { Container, Content, Header, StyledAlertCircleIcon, StyledButton, StyledCheckCircleIcon } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { pageUrls } from '../urls';
import { Typography } from '@mui/material';
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";

export const VerifyAccount = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const { verifyUser } = useContext(ApiRequestContext);
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      verifyUser.request({
        variables: {
          token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (verifyUser.isFinished) {
      if (verifyUser.data?.statusCode === 200) {
        setIsSuccess(true);
      }
    }
  }, [verifyUser.data?.statusCode, verifyUser.isFinished]);

  const handleLoginClick = () => {
    // navigate(`${pageUrls.main()}?login`);
    navigate(pageUrls.main());
  };

  const handleGoBackClick = () => {
    navigate(pageUrls.main());
  };

  return (
    <Container>
      <Content>
        {verifyUser.response?.called && verifyUser.response?.loading ? (
          <>
            <Header variant="h1">Verify Account</Header>
            <Typography variant="h1">Verifying, please wait...</Typography>
          </>
        ) : isSuccess ? (
          <>
            <StyledCheckCircleIcon />
            <Header variant="h1">Verify Account</Header>
            <Typography variant="body1">Your account was successfully verified. Now you can login</Typography>
            <StyledButton variant="contained" onClick={handleLoginClick}>
              Login
            </StyledButton>
          </>
        ) : (
          <>
            <StyledAlertCircleIcon />
            <Header variant="h1">Verify Account</Header>
            <Typography variant="body1">Something went wrong during verification of your account</Typography>
            <StyledButton variant="contained" onClick={handleGoBackClick}>
              Go back
            </StyledButton>
          </>
        )}
      </Content>
    </Container>
  );
};
