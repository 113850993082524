import { ApolloLink } from '@apollo/client';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/localStorage';
import { Constants } from '@tour-buddy/interfaces';

export const logoutMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (operation.operationName === 'LogoutUser' || response.data?.authUser === null || response.data?.removeUser) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    }

    return response;
  });
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  const refreshToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const sessionId = getLocalStorageItem(LOCAL_STORAGE_KEYS.SESSION_ID);

  if (authToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken}`,
        [Constants.REQUEST_HEADERS.REFRESH_TOKEN]: refreshToken,
        [Constants.REQUEST_HEADERS.SESSION_ID]: sessionId,
      },
    });
  } else {
    operation.setContext({
      headers: {
        [Constants.REQUEST_HEADERS.SESSION_ID]: sessionId,
      },
    });
  }

  return forward(operation).map((response) => {
    const responseObject = operation.getContext().response;

    const userData = response?.data?.authUser?.data?.user ?? response?.data?.updateUser?.data?.user;
    if (userData) {
      setLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA, JSON.stringify(userData));
    }

    const authToken = responseObject.headers.get(Constants.REQUEST_HEADERS.AUTH_TOKEN);
    const refreshToken = responseObject.headers.get(Constants.REQUEST_HEADERS.REFRESH_TOKEN);
    if (authToken) setLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, authToken);
    if (refreshToken) setLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

    return response;
  });
}).setOnError((error, observer) => {
  console.log(error);
  console.log(observer);
});
