import { gql } from '@apollo/client';

export const REMOVE_USER_MUTATION = gql`
  mutation RemoveUser($userId: String!) {
    removeUser(input: { id: $userId }) {
      data
      statusCode
      code
      message
    }
  }
`;
