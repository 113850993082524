import { styled } from '@mui/material';
import { Button, Card, Modal, TextField } from '@mui/material';

export const Container = styled(Modal)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Card)`
  margin: 8px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
`;

export const TextInput = styled(TextField)`
  margin: 8px 0;
`;

export const SubmitButton = styled(Button)`
  margin: 32px 0 8px;
`;

export const InputsContainer = styled('div')`
  margin-top: 48px;
  display: flex;
  flex-flow: column;
`;
