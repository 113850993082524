import React, { ReactNode, useState } from 'react';
import { LoginModal } from './loginModal';

export const LoginModalOpenContext = React.createContext({ isOpen: false, toggleIsOpen: () => {} });

export const LoginModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <LoginModalOpenContext.Provider value={{ isOpen, toggleIsOpen }}>
      {children}
      <LoginModal />
    </LoginModalOpenContext.Provider>
  );
};
