import { gql } from '@apollo/client';

export const VERIFY_USER_MUTATION = gql`
  mutation VerifyAccount($token: String!) {
    verifyUser(input: { token: $token }) {
      data {
        id
      }
      statusCode
      code
      message
    }
  }
`;
