import React from 'react';
import { ApiProviderProps } from './types';
import { initialDataValues } from './constants/initialDataValues';
import { initialRequestValues } from './constants/initialRequestValues';
import { useApiProvider } from "./hooks/useApiProvider";

export const ApiDataContext = React.createContext(initialDataValues);
export const ApiRequestContext = React.createContext(initialRequestValues);

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const { data, requests } = useApiProvider();

  return (
    <ApiRequestContext.Provider value={requests}>
      <ApiDataContext.Provider value={data}>
        {children}
      </ApiDataContext.Provider>
    </ApiRequestContext.Provider>
  );
};
