export enum QueriesNames {
  mapPlaces = 'mapPlaces',
  nearbyPlaces = 'nearbyPlaces',
  selectedPlace = 'selectedPlace',
  userPlans = 'userPlans',
  selectedUserPlan = 'selectedUserPlan',
  updatePlaceUserPlans = 'updatePlaceUserPlans',
  updateUserPlan = 'updateUserPlan',
  addPlaceImageInit = 'addPlaceImageInit',
  addFact = 'addFact',
  addUserPlan = 'addUserPlan',
  loginUser = 'loginUser',
  resetPassword = 'resetPassword',
  forgotPassword = 'forgotPassword',
  verifyUser = 'verifyUser',
  createUser = 'createUser',
  updateUser = 'updateUser',
  logoutUser = 'logoutUser',
  removeUser = 'removeUser',
  updatePassword = 'updatePassword',
}
