import React, { useContext, useEffect } from 'react';
import { Fade, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LoginModalProps } from './types';
import { Container, Content, InputsContainer, SubmitButton, TextInput } from './styles';
import { CreateUserPlanModalOpenContext } from './createUserPlanModalProvider';
import { useSnackbar } from 'notistack';
import { useInputString } from '../../hooks/useInputString';
import { useInputBoolean } from '../../hooks/useInputBoolean';
import { ApiRequestContext } from '../../api';

export const CreateUserPlanModal: React.FC<LoginModalProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { isOpen, toggleIsOpen } = useContext(CreateUserPlanModalOpenContext);
  const name = useInputString({ required: true });
  const isPrivate = useInputBoolean();
  const { addUserPlan } = useContext(ApiRequestContext);

  useEffect(() => {
    const shouldOpenOnLoad = searchParams.get('createUserPlan');
    if (shouldOpenOnLoad === '') toggleIsOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (addUserPlan.isFinished) {
      if (addUserPlan.data?.statusCode === 200) {
        enqueueSnackbar('User plan created');
        toggleIsOpen();
      } else {
        enqueueSnackbar('Something went wrong');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserPlan.isFinished, addUserPlan.data]);

  const handleClose = () => {
    toggleIsOpen();
  };

  const handleUserPlanSubmit = () => {
    addUserPlan.request({
      variables: {
        name: name.value,
        isPrivate: isPrivate.value,
      },
    });
  };

  return (
    <Container open={isOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Content>
          <Typography variant="h3" component="h3">
            Create your new trip plan
          </Typography>
          <InputsContainer>
            <TextInput autoFocus label="Name" variant="outlined" {...name} />
            <FormGroup>
              <FormControlLabel label="is plan private?" control={<Switch {...isPrivate} />} />
            </FormGroup>
            <SubmitButton fullWidth variant="contained" onClick={handleUserPlanSubmit} size="large">
              Create
            </SubmitButton>
          </InputsContainer>
        </Content>
      </Fade>
    </Container>
  );
};
