import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    createUser(user: { email: $email, password: $password, firstName: $firstName, lastName: $lastName }) {
      statusCode
      code
      message
      data {
        id
      }
    }
  }
`;
