import { gql } from '@apollo/client';

export const GET_PLACES_QUERY = gql`
  query Place($q: String!) {
    places(name: $q) {
      id
      name
      location {
        latitude
        longitude
        country
        city
        address
      }
    }
  }
`;
