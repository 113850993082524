import React from 'react';
import { Toolbar } from '@mui/material';
import { AppBarProps } from './types';
import { AppBarStyled, Container, Content } from './styles';
import { SearchBar } from '../searchBar';
import { SearchMobile } from './searchMobile';
import { Logo } from '../logo';

export const Header: React.FC<AppBarProps> = ({ searchValue, onSearchChange, enableSearch = false }) => {
  return (
    <Container>
      <AppBarStyled position="fixed" elevation={0}>
        <Content>
          <Logo className="logo" />
          {enableSearch && (
            <>
              <SearchBar value={searchValue} onSearchChange={onSearchChange} showMobile={false} />
              <SearchMobile searchValue={searchValue} onSearchChange={onSearchChange} />
            </>
          )}
        </Content>
      </AppBarStyled>
      <Toolbar />
    </Container>
  );
};
