import React from 'react';
import { Typography } from '@mui/material';
import { BackButton, ForgetPasswordSuccessContainer, StyledSendEmailFlyIcon } from './styles';

interface ForgotPasswordContentProps {
  onBack: () => void;
}
export const ContentForgotPasswordSuccess = ({ onBack }: ForgotPasswordContentProps) => {
  const handleBack = () => {
    onBack();
  };

  return (
    <ForgetPasswordSuccessContainer>
      <StyledSendEmailFlyIcon />
      <Typography variant="h3" component="h3">
        Check your inbox
      </Typography>
      <Typography variant="body1" component="p">
        We sent a password reset link to your email
      </Typography>
      <BackButton fullWidth variant="contained" onClick={handleBack} size="large">
        back to login
      </BackButton>
    </ForgetPasswordSuccessContainer>
  );
};
