var _a;
import { QueriesNames } from "./queriesNames";
export var queryFieldMap = (_a = {},
    _a[QueriesNames.sendVerificationUser] = "sendVerification",
    _a[QueriesNames.authUser] = "authUser",
    _a[QueriesNames.mapPlaces] = "places",
    _a[QueriesNames.searchPlaces] = "places",
    _a[QueriesNames.nearbyPlaces] = "places",
    _a[QueriesNames.placesByIds] = "places",
    _a[QueriesNames.popularCityPlaces] = "place",
    _a[QueriesNames.selectedPlace] = "place",
    _a[QueriesNames.userPlans] = "userPlans",
    _a[QueriesNames.selectedUserPlan] = "userPlan",
    _a[QueriesNames.updateUserPlan] = "updateUserPlan",
    _a[QueriesNames.removeUserPlan] = "removeUserPlan",
    _a[QueriesNames.updatePlaceUserPlans] = "updatePlaceUserPlans",
    _a[QueriesNames.addPlaceImageInit] = "addPlaceImageInit",
    _a[QueriesNames.addFact] = "addFact",
    _a[QueriesNames.addUserPlan] = "addUserPlan",
    _a[QueriesNames.loginUser] = "login",
    _a[QueriesNames.resetPassword] = "resetPassword",
    _a[QueriesNames.forgotPassword] = "forgotPassword",
    _a[QueriesNames.updatePassword] = "updateUser",
    _a[QueriesNames.verifyUser] = "verifyUser",
    _a[QueriesNames.createUser] = "createUser",
    _a[QueriesNames.updateUser] = "updateUser",
    _a[QueriesNames.logoutUser] = "logout",
    _a[QueriesNames.removeUser] = "removeUser",
    _a[QueriesNames.location] = "location",
    _a[QueriesNames.locationSuggestions] = "locations",
    _a[QueriesNames.subscribeNewsletter] = "subscribeNewsletter",
    _a);
