import { gql } from '@apollo/client';

export const ADD_USER_PLAN_MUTATION = gql`
  mutation CreateUserPlan($name: String!, $isPrivate: Boolean!) {
    addUserPlan(newPlan: { name: $name, isPrivate: $isPrivate }) {
      data {
        id
        name
      }
      statusCode
      code
      message
    }
  }
`;
