import {
  ForgotPasswordButton,
  Header,
  InputsContainer,
  LoginButton,
  RegisterButton,
  RegisterText,
  SignUpContainer,
  TextInput,
} from './styles';
import React, { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LOCAL_STORAGE_KEYS, setLocalStorageItem } from '../../utils/localStorage';
import { ApiRequestContext } from '../../api';

type SetMethod = (v: string | ((p: string) => string)) => void;
const handleInputChange = (setMethod: SetMethod) => (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value);

interface LoginContentProps {
  onClose: () => void;
  onForgotPassword: () => void;
  onRegisterClick: () => void;
}
export const ContentLogin = ({ onClose, onForgotPassword, onRegisterClick }: LoginContentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginUser } = useContext(ApiRequestContext);

  const handleForgotPasswordClick = () => {
    onForgotPassword();
  };

  const handleRegisterClick = () => {
    onRegisterClick();
  };

  const handleLoginSubmit = () => {
    if (!email || !password) return;

    loginUser.request({ variables: { email, password } });
  };

  const handleInputEnterClick = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLoginSubmit();
    }
  };

  useEffect(() => {
    if (loginUser.isFinished && loginUser.data) {
      if (loginUser.data?.statusCode === 401) {
        enqueueSnackbar('User and password does not match');
      }
      if (loginUser.data?.statusCode === 200 && loginUser.data?.data) {
        const { auth, user } = loginUser.data?.data;
        setLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA, JSON.stringify(user));
        setLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, auth.authToken);
        setLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, auth.refreshToken);
        enqueueSnackbar('You are logged in');
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isFinished, loginUser.data]);

  return (
    <>
      <Header variant="h3">Sign in</Header>
      <SignUpContainer>
        <RegisterText variant="body1">New to Wizytor?</RegisterText>
        <RegisterButton variant="text" onClick={handleRegisterClick}>
          Sign up
        </RegisterButton>
      </SignUpContainer>
      <InputsContainer>
        <TextInput
          autoFocus
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={handleInputChange(setEmail)}
          onKeyPress={handleInputEnterClick}
        />
        <TextInput
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={handleInputChange(setPassword)}
          onKeyPress={handleInputEnterClick}
        />
        <LoginButton fullWidth variant="contained" onClick={handleLoginSubmit} size="large">
          Login
        </LoginButton>
        <ForgotPasswordButton variant="text" onClick={handleForgotPasswordClick}>
          Forgot password?
        </ForgotPasswordButton>
      </InputsContainer>
    </>
  );
};
