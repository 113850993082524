import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $userId: String!
    $firstName: String
    $lastName: String
    $oldPassword: String
    $newPassword: String
  ) {
    updateUser(
      input: {
        id: $userId
        oldPassword: $oldPassword
        newPassword: $newPassword
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      data {
        id
        email
        firstName
        lastName
      }
      statusCode
      code
      message
    }
  }
`;
