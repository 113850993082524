import React, { useContext } from 'react';
import { LayoutProps } from './types';
import { Container, ContentContainer } from './styles';
import { Header } from '../header';
import { NavBarDrawer, NavBarOpenContext } from '../navBarDrawer';

export const Layout: React.FC<LayoutProps> = ({
  searchValue,
  onSearchChange,
  children,
  enableSearch,
  hideDrawer = false,
}) => {
  const { navbarIsOpen } = useContext(NavBarOpenContext);

  return (
    <Container>
      {!hideDrawer && <NavBarDrawer />}
      <Header searchValue={searchValue} onSearchChange={onSearchChange} enableSearch={enableSearch} />
      <ContentContainer isDrawerOpen={navbarIsOpen} hideDrawer={hideDrawer}>
        {children}
      </ContentContainer>
    </Container>
  );
};
