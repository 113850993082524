import { useMutation } from '@apollo/client';
import { Constants } from '@tour-buddy/interfaces';
import { ADD_TRACKING_MUTATION } from './queries';
import { getUrl } from './utils';
import { TrackingEventUserAction, UseTrackingUserActionParams } from './types';
import { useSession } from '../useSession';

const SKIP = true;

export const useTrackingUserAction = () => {
  const [sendTrackingRequest] = useMutation(ADD_TRACKING_MUTATION);

  const url = getUrl();
  const { sessionId } = useSession();

  const trackUserAction = ({
    componentPayload,
    componentName,
    isList,
    listPosition,
    listCount,
    actionValue,
    actionType,
  }: UseTrackingUserActionParams) => {
    if (SKIP) return;

    if (!sessionId) return;

    const trackingEvent: TrackingEventUserAction = {
      type: Constants.TRACKING_EVENT_TYPES.userAction,
      sessionId: sessionId as string,
      payload: {
        url,
        actionType,
        actionValue,
        componentName,
        componentPayload,
        isList,
        listPosition,
        listCount,
      },
    };

    sendTrackingRequest({
      variables: {
        type: trackingEvent.type,
        sessionId: trackingEvent.sessionId,
        url: trackingEvent.payload.url,
        componentName: trackingEvent.payload.componentName,
        componentProps: JSON.stringify(trackingEvent.payload.componentPayload),
        actionType: trackingEvent.payload.actionType,
        actionValue: JSON.stringify(trackingEvent.payload.actionValue),
        isList: trackingEvent.payload.isList,
        listPosition: trackingEvent.payload.listPosition,
        listCount: trackingEvent.payload.listCount,
      },
    });
  };

  return { trackUserAction, trackingActionTypes: Constants.TRACKING_PAYLOAD_ACTION_TYPES };
};
