import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import { NavigationMenuIcon } from '../icons';

export const Container = styled(Box)`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

export const Content = styled(Toolbar)`
  justify-content: space-between;
  align-items: flex-start;

  & .logo {
    width: auto;
    height: 28px;
    margin-top: 16px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 32px;
      margin-top: 18px;
    }
  }
`;

export const AppBarStyled = styled(AppBar)`
  background-color: white;
  height: 64px;
  display: flex;
  justify-content: center;
`;

export const SearchIconMobile = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const SearchContainerMobile = styled('div')`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: white;
  width: 100%;
  transition: all 300ms;
  padding: 8px;
`;

export const StyledNavigationMenuIcon = styled(NavigationMenuIcon)`
  width: 100%;
  height: 100%;
  stroke: ${({ theme }) => theme.palette.primary.main};
`;

export const NavigationMenuIconContainer = styled(IconButton)`
  width: 48px;
  height: 48px;
  align-self: center;
`;
