import { InputsContainer, LoginButton, TextInput } from './styles';
import React, { KeyboardEvent, useEffect } from "react";
import { Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from './queries';
import { useSnackbar } from 'notistack';
import { useInputString } from '../../hooks/useInputString';
import {
  emailInputProps,
  firstNameInputProps,
  lastNameInputProps,
  passwordConfirmInputProps,
  passwordInputProps,
} from './constants';

interface ContentRegisterProps {
  onSuccess: () => void;
}

export const ContentRegister = ({ onSuccess }: ContentRegisterProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const email = useInputString(emailInputProps);
  const firstName = useInputString(firstNameInputProps);
  const lastName = useInputString(lastNameInputProps);
  const password = useInputString(passwordInputProps);
  const passwordConfirm = useInputString(passwordConfirmInputProps({ password }));
  const [createUserRequest, { data, loading, called }] = useMutation(CREATE_USER_MUTATION);

  useEffect(() => {
    if (called && !loading) {
      if (data?.createUser?.statusCode === 200) {
        enqueueSnackbar('User created');
        onSuccess();
      } else {
        const message = data?.createUser?.message || 'Something went wrong';
        enqueueSnackbar(message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, called, loading]);

  const handleRegisterSubmit = () => {
    if (!email.value || !firstName.value || !lastName.value || !password.value || !passwordConfirm.value) return;
    if (password.value !== passwordConfirm.value) return;

    createUserRequest({
      variables: {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
      },
    });
  };

  const handleInputEnterClick = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleRegisterSubmit();
    }
  };

  return (
    <>
      <Typography variant="h3" component="h3">
        Create your wizytor account
      </Typography>
      <InputsContainer>
        <TextInput
          autoFocus
          label="Email"
          variant="outlined"
          {...email}
          onKeyPress={handleInputEnterClick}
        />
        <TextInput
          label="First name"
          variant="outlined"
          {...firstName}
          onKeyPress={handleInputEnterClick}
        />
        <TextInput
          label="Last name"
          variant="outlined"
          {...lastName}
          onKeyPress={handleInputEnterClick}
        />
        <TextInput
          label="Password"
          variant="outlined"
          {...password}
          onKeyPress={handleInputEnterClick}
        />
        <TextInput
          label="Confirm password"
          variant="outlined"
          {...passwordConfirm}
          onKeyPress={handleInputEnterClick}
        />
        <LoginButton fullWidth variant="contained" onClick={handleRegisterSubmit} size="large">
          Register
        </LoginButton>
      </InputsContainer>
    </>
  );
};
