export var QueriesNames;
(function (QueriesNames) {
    QueriesNames["sendVerificationUser"] = "sendVerificationUser";
    QueriesNames["authUser"] = "authUser";
    QueriesNames["mapPlaces"] = "mapPlaces";
    QueriesNames["searchPlaces"] = "searchPlaces";
    QueriesNames["nearbyPlaces"] = "nearbyPlaces";
    QueriesNames["placesByIds"] = "placesByIds";
    QueriesNames["popularCityPlaces"] = "popularCityPlaces";
    QueriesNames["selectedPlace"] = "selectedPlace";
    QueriesNames["userPlans"] = "userPlans";
    QueriesNames["selectedUserPlan"] = "selectedUserPlan";
    QueriesNames["updatePlaceUserPlans"] = "updatePlaceUserPlans";
    QueriesNames["updateUserPlan"] = "updateUserPlan";
    QueriesNames["removeUserPlan"] = "removeUserPlan";
    QueriesNames["addPlaceImageInit"] = "addPlaceImageInit";
    QueriesNames["addFact"] = "addFact";
    QueriesNames["addUserPlan"] = "addUserPlan";
    QueriesNames["loginUser"] = "loginUser";
    QueriesNames["resetPassword"] = "resetPassword";
    QueriesNames["forgotPassword"] = "forgotPassword";
    QueriesNames["verifyUser"] = "verifyUser";
    QueriesNames["createUser"] = "createUser";
    QueriesNames["updateUser"] = "updateUser";
    QueriesNames["logoutUser"] = "logoutUser";
    QueriesNames["removeUser"] = "removeUser";
    QueriesNames["updatePassword"] = "updatePassword";
    QueriesNames["location"] = "location";
    QueriesNames["locationSuggestions"] = "locationSuggestions";
    QueriesNames["subscribeNewsletter"] = "subscribeNewsletter";
})(QueriesNames || (QueriesNames = {}));
