import { ApiRequest, Request } from "../types";
import { QueryResult } from "@apollo/client/react/types/types";

const emptyApiRequest: ApiRequest = {
  request: (async () => ({})) as any as Request,
  response: {} as QueryResult,
  data: null,
  isFinished: false,
};

export const initialRequestValues = {
  getMapPlaces: emptyApiRequest,
  getNearbyPlaces: emptyApiRequest,
  getSelectedPlace: emptyApiRequest,
  getUserPlans: emptyApiRequest,
  getSelectedUserPlan: emptyApiRequest,
  updatePlaceUserPlans: emptyApiRequest,
  updateUserPlan: emptyApiRequest,
  addPlaceImageInit: emptyApiRequest,
  addFact: emptyApiRequest,
  addUserPlan: emptyApiRequest,
  loginUser: emptyApiRequest,
  resetPassword: emptyApiRequest,
  forgotPassword: emptyApiRequest,
  verifyUser: emptyApiRequest,
  createUser: emptyApiRequest,
  updateUser: emptyApiRequest,
  logoutUser: emptyApiRequest,
  removeUser: emptyApiRequest,
  updatePassword: emptyApiRequest,
};
