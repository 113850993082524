import { ChangeEventHandler, useEffect, useState } from 'react';

export interface UseInputProps {
  defaultValue?: boolean;
  type?: string;
  required?: boolean;
}

export interface UseInputResponse {
  onChange: ChangeEventHandler<HTMLElement>;
  value: boolean;
  checked: boolean;
  error: boolean;
  required: boolean;
}

export const useInputBoolean = (props: UseInputProps = {}): UseInputResponse => {
  const { defaultValue = false, required = false } = props;

  const [value, setValue] = useState<boolean>(defaultValue);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (required && !value) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [value, required]);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.checked;
    setValue(newValue);
  };

  return {
    onChange: handleOnChange,
    value,
    checked: value,
    error: isError,
    required,
  };
};
