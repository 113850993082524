import { styled, Typography } from '@mui/material';
import { Button, Card, Modal, TextField } from '@mui/material';
import { SendEmailFlyIcon } from '../../components/icons';

export const Container = styled(Modal)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Card)`
  margin: 8px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
`;

export const TextInput = styled(TextField)`
  margin: 8px 0;
`;

export const LoginButton = styled(Button)`
  margin: 16px 0 8px;
`;

export const ForgotPasswordButton = styled(Button)`
  margin: 8px 0 8px;
`;

export const SignUpContainer = styled('div')`
  margin-top: 16px;
  display: flex;
  align-items: baseline;$
`;

export const RegisterText = styled(Typography)`
  text-align: center;
`;

export const Header = styled(Typography)`
  text-align: center;
`;

export const RegisterButton = styled(Button)``;

export const InputsContainer = styled('div')`
  margin-top: 8px;
  display: flex;
  flex-flow: column;
`;

export const BackButton = styled(Button)`
  margin: 32px 0 16px;
`;

export const StyledSendEmailFlyIcon = styled(SendEmailFlyIcon)`
  width: 48px;
  height: 48px;
  margin: 16px 0;
  stroke: ${({ theme }) => theme.palette.primary.main};
`;

export const ForgetPasswordSuccessContainer = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 8px 0;
  }
`;
