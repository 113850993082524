import { Models } from '@tour-buddy/interfaces';
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from '../utils/localStorage';

interface UseAuth {
  isAuthorized: boolean;
  user: Models.User | null;
}

export const useAuth = (): UseAuth => {
  let isAuthorized = false;
  const authToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  const userData = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);

  if (authToken && userData) isAuthorized = true;

  const user = userData ? (JSON.parse(userData) as Models.User) : null;

  return { isAuthorized, user };
};
