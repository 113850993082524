import { styled } from '@mui/material/styles';
import { Button, TextField, Typography } from '@mui/material';

export const Container = styled('div')`
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Content = styled('div')`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin: 8px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const Header = styled(Typography)`
  margin-bottom: 32px;
`;

export const Input = styled(TextField)`
  margin: 16px 0;
`;

export const StyledButton = styled(Button)`
    margin: 64px 0;
    padding: 4px 32px;
`;

export const FormContainer = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
