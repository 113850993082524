import React from 'react';
import { UserAvatarProps } from './types';
import { StyledAvatar } from './styles';

const getFirstLetter = (text: string = ''): string => text.charAt(0).toUpperCase();

export const UserAvatar = ({ lastName, firstName }: UserAvatarProps) => (
  <StyledAvatar>
    {getFirstLetter(firstName)}
    {getFirstLetter(lastName)}
  </StyledAvatar>
);
