import React, { useContext, useEffect, useState } from "react";
import { Container, Content } from './styles';
import { useTrackingPageView } from '../../hooks/useTracking';
import { useSearchParams } from 'react-router-dom';
import { ContentResetPassword } from './contentResetPassword';
import { ContentResetPasswordError } from './contentResetPasswordError';
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { ContentResetPasswordSuccess } from "./contentResetPasswordSuccess";

enum PageVariant {
  form = 'form',
  success = 'success',
  error = 'error',
}

const PAGE_VARIANT_MAP = {
  [PageVariant.form]: ContentResetPassword,
  [PageVariant.success]: ContentResetPasswordSuccess,
  [PageVariant.error]: ContentResetPasswordError,
};

export const ResetPassword = () => {
  useTrackingPageView();
  const [searchParams] = useSearchParams();
  const { resetPassword } = useContext(ApiRequestContext);

  const [pageVariant, setPageVariant] = useState<PageVariant>(PageVariant.form);
  const forgotPasswordToken = searchParams.get('token');

  useEffect(() => {
    if (!forgotPasswordToken) {
      setPageVariant(PageVariant.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetPassword.isFinished) {
      if (resetPassword.data?.statusCode === 200) {
        setPageVariant(PageVariant.success);
      } else {
        setPageVariant(PageVariant.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassword.isFinished]);

  const PageVariantComponent = PAGE_VARIANT_MAP[pageVariant] || ContentResetPassword;

  return (
    <Container>
      <Content>
        <PageVariantComponent />
      </Content>
    </Container>
  );
};
