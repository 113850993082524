import React, { useContext, useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LoginModalProps } from './types';
import { Container, Content } from './styles';
import { RegisterModalOpenContext } from './registerModalProvider';
import { ContentRegister } from './contentRegister';
import { ContentRegisterSuccess } from './contentRegisterSuccess';

enum CONTENT_TYPE {
  REGISTER = 'REGISTER',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
}
export const RegisterModal: React.FC<LoginModalProps> = () => {
  const [searchParams] = useSearchParams();
  const { isOpen, toggleIsOpen } = useContext(RegisterModalOpenContext);
  const [contentType, setContentType] = useState(CONTENT_TYPE.REGISTER);

  useEffect(() => {
    const shouldOpenOnLoad = searchParams.get('register');
    if (shouldOpenOnLoad === '') toggleIsOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setContentType(CONTENT_TYPE.REGISTER);
    toggleIsOpen();
  };

  const handleUserCreatedSuccessfully = () => {
    setContentType(CONTENT_TYPE.REGISTER_SUCCESS);
  };

  return (
    <Container open={isOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Content>
          {contentType === CONTENT_TYPE.REGISTER && <ContentRegister onSuccess={handleUserCreatedSuccessfully} />}
          {contentType === CONTENT_TYPE.REGISTER_SUCCESS && <ContentRegisterSuccess />}
        </Content>
      </Fade>
    </Container>
  );
};
