import React from 'react';
import { IconProps } from './types';

export const SingleNeutralActionsAddIcon = ({ className, style }: IconProps) => (
  <svg style={style} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5">
    <path
      d="M11.250 17.250 A6.000 6.000 0 1 0 23.250 17.250 A6.000 6.000 0 1 0 11.250 17.250 Z"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path d="M17.25 14.25L17.25 20.25" fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></path>
    <path d="M14.25 17.25L20.25 17.25" fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></path>
    <path
      d="M.75,17.25a6.753,6.753,0,0,1,9.4-6.208"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M3.375 4.875 A4.125 4.125 0 1 0 11.625 4.875 A4.125 4.125 0 1 0 3.375 4.875 Z"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
