import { styled } from '@mui/material';
import { Button, Card, Modal, TextField } from '@mui/material';
import { SendEmailFlyIcon } from '../../components/icons';

export const Container = styled(Modal)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Card)`
  margin: 8px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
`;

export const TextInput = styled(TextField)`
  margin: 8px 0;
`;

export const LoginButton = styled(Button)`
  margin: 16px 0 0;
`;

export const InputsContainer = styled('div')`
  margin-top: 48px;
  display: flex;
  flex-flow: column;
`;

export const StyledSendEmailFlyIcon = styled(SendEmailFlyIcon)`
  width: 48px;
  height: 48px;
  margin: 16px 0;
  stroke: ${({ theme }) => theme.palette.primary.main};
`;

export const RegisterSuccessContainer = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 8px 0;
  }
`;
