import { gql } from '@apollo/client';

export const GET_USER_PLANS_QUERY = gql`
  query UserPlans {
    userPlans {
      data {
        id
        name
        placeIds
        places {
          id
          name
        }
      }
      statusCode
      code
      message
    }
  }
`;
