import { DynamicItemsUserPlanParams, NavBarItem } from './types';
import {
  PowerButtonIcon,
  SingleNeutralActionsAddIcon,
  TripDestinationIcon,
  AddCircleIcon,
  EarthLocateIcon,
  HouseIcon,
  LockIcon,
} from '../icons';
import { pageUrls } from '../../pages/urls';
import { UserAvatar } from '../userAvatar';
import { Models } from '@tour-buddy/interfaces';

export const ITEMS_TOP: NavBarItem[] = [
  {
    Icon: HouseIcon,
    text: 'Discover',
    onClick: ({ navigate }) => navigate(pageUrls.main()),
    condition: () => true,
  },
  {
    Icon: EarthLocateIcon,
    text: 'Map',
    onClick: ({ navigate }) => navigate(pageUrls.map()),
    condition: () => true,
  },
  {
    Icon: TripDestinationIcon,
    text: 'User Plans',
    onClick: ({ togglePlansOpen, navigate }) => {
      // navigate(pageUrls.main());
      togglePlansOpen();
    },
    condition: ({ isAuthorized }) => isAuthorized,
  },
];

export const ITEMS_BOTTOM: NavBarItem[] = [
  {
    Icon: SingleNeutralActionsAddIcon,
    text: 'Create account',
    onClick: ({ toggleRegisterModalOpen }) => {
      toggleRegisterModalOpen();
    },
    condition: ({ isAuthorized }) => !isAuthorized,
  },
  {
    Icon: LockIcon,
    text: 'Login',
    onClick: ({ toggleLoginModalOpen }) => {
      toggleLoginModalOpen();
    },
    condition: ({ isAuthorized }) => !isAuthorized,
  },
  {
    Icon: UserAvatar,
    text: 'User Profile',
    onClick: ({ navigate }) => navigate(pageUrls.userAccount()),
    condition: ({ isAuthorized }) => isAuthorized,
  },
  {
    Icon: PowerButtonIcon,
    text: 'Logout',
    onClick: ({ logoutRequest, navigate, closeSubNavbar }) => {
      logoutRequest();
      navigate(pageUrls.main());
      closeSubNavbar();
    },
    condition: ({ isAuthorized }) => isAuthorized,
  },
];

export const DYNAMIC_ITEMS_USER_PLANS =
  ({ toggleCreateUserPlanModalOpen, navigate, closeSubNavbar }: DynamicItemsUserPlanParams) =>
  (plans: Partial<Models.UserPlan>[] = []): NavBarItem[] =>
    [
      {
        Icon: AddCircleIcon,
        text: 'Add Plan',
        onClick: () => toggleCreateUserPlanModalOpen(),
      },
      ...plans?.map(({ id, name, placeIds, isPrivate }) => ({
        text: name ?? '',
        secondaryText: `${placeIds?.length ?? 0} places`,
        onClick: () => {
          closeSubNavbar();
          navigate(pageUrls.planDetails(id));
        },
      })),
    ];

export const SUB_NAV_BAR_TITLES = {
  PLANS: 'Plans',
};
