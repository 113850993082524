import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Constants } from '@tour-buddy/interfaces';
import { ADD_TRACKING_MUTATION } from './queries';
import { getUrl } from './utils';
import { TrackingEventComponentView, UseTrackingComponentView } from './types';
import { useIsInViewport } from '../useIsInViewport';
import { useSession } from '../useSession';

const SKIP = true;

export const useTrackingComponentView = ({
  ref,
  componentPayload,
  componentName,
  isList,
  listCount,
  listPosition,
}: UseTrackingComponentView) => {
  const [wasShown, setWasShown] = useState(false);
  const isInViewport = useIsInViewport(ref);
  const [sendTrackingRequest] = useMutation(ADD_TRACKING_MUTATION);
  const { sessionId } = useSession();

  const url = getUrl();

  const trackComponentView = () => {
    if (SKIP) return;

    const trackingEvent: TrackingEventComponentView = {
      type: Constants.TRACKING_EVENT_TYPES.componentView,
      sessionId: sessionId as string,
      payload: {
        url,
        componentName,
        componentPayload,
        isList,
        listCount,
        listPosition,
      },
    };

    sendTrackingRequest({
      variables: {
        type: trackingEvent.type,
        sessionId: trackingEvent.sessionId,
        url: trackingEvent.payload.url,
        componentName: trackingEvent.payload.componentName,
        componentProps: JSON.stringify(trackingEvent.payload.componentPayload),
        isList: trackingEvent.payload.isList,
        listPosition: trackingEvent.payload.listPosition,
        listCount: trackingEvent.payload.listCount,
      },
    });
  };

  useEffect(() => {
    if (!wasShown && isInViewport && sessionId) {
      setWasShown(true);
      trackComponentView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport]);
};
