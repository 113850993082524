import { InputsContainer, LoginButton, TextInput } from './styles';
import React, { KeyboardEvent, useEffect } from "react";
import { Button, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD_MUTATION } from './queries';
import { useSnackbar } from 'notistack';
import { useInputString } from '../../hooks/useInputString';

interface ForgotPasswordContentProps {
  onBack: () => void;
  onSuccess: () => void;
}
export const ContentForgotPassword = ({ onBack, onSuccess }: ForgotPasswordContentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const email = useInputString({
    type: 'email',
    required: true,
    validation: [
      {
        message: 'Invalid email',
        pattern:
          '/^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/',
      },
    ],
  });
  const [forgotPasswordRequest, { called, loading, data }] = useMutation(FORGOT_PASSWORD_MUTATION);

  const handleBackToLogin = () => {
    onBack();
  };

  const handleForgotPasswordSubmit = () => {
    forgotPasswordRequest({ variables: { email: email.value } });
  };

  const handleInputEnterClick = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleForgotPasswordSubmit();
    }
  };

  useEffect(() => {
    if (called && !loading && data.forgotPassword?.data) {
      enqueueSnackbar('Email was sent');
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, loading, data]);

  return (
    <>
      <Typography variant="h3" component="h3">
        Forgot your password?
      </Typography>
      <InputsContainer>
        <TextInput
          autoFocus
          label="Email"
          variant="outlined"
          onKeyPress={handleInputEnterClick}
          {...email}
        />
        <LoginButton fullWidth variant="contained" onClick={handleForgotPasswordSubmit} size="large">
          Send me instructions
        </LoginButton>
        <Button variant="text" onClick={handleBackToLogin}>
          Go back to login
        </Button>
      </InputsContainer>
    </>
  );
};
