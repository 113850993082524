import React from 'react';
import { IconProps } from './types';

export const EarthLocateIcon = ({ className, style }: IconProps) => (
  <svg
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.2899 22.9634C12.124 23.4158 9.87257 23.2166 7.8198 22.3907C5.76702 21.5649 4.00485 20.1496 2.75564 18.3233C1.50643 16.497 0.826162 14.3416 0.80067 12.1291C0.775178 9.91657 1.40561 7.74608 2.61241 5.89149C3.81921 4.03691 5.5483 2.58133 7.5815 1.70843C9.6147 0.835531 11.8609 0.584428 14.0367 0.986805C16.2124 1.38918 18.2203 2.42701 19.8068 3.96933C21.3933 5.51165 22.4875 7.48935 22.9512 9.65287"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.03117 20.7733L6.02673 15.7458H7.10193C7.32899 15.7466 7.55317 15.6949 7.75695 15.5947C7.96073 15.4945 8.1386 15.3486 8.27668 15.1684C8.41891 14.9923 8.51742 14.785 8.56414 14.5635C8.61086 14.342 8.60446 14.1126 8.54548 13.8941L7.79881 10.9074C7.72057 10.5836 7.53562 10.2956 7.27376 10.0897C7.0119 9.88383 6.68836 9.77208 6.35526 9.77246H1.04895"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.96 5.29248H16.8982C16.5651 5.2921 16.2415 5.40385 15.9797 5.60973C15.7178 5.81561 15.5329 6.10363 15.4546 6.42741L14.8075 9.02581"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.72 11.2659C19.3087 11.2646 19.8918 11.3796 20.436 11.6042C20.9801 11.8289 21.4745 12.1588 21.8908 12.5751C22.307 12.9914 22.637 13.4858 22.8617 14.0299C23.0863 14.574 23.2013 15.1572 23.2 15.7459C23.2 17.6573 20.522 21.3011 19.3074 22.8541C19.2375 22.9432 19.1483 23.0151 19.0466 23.0646C18.9448 23.1141 18.8332 23.1398 18.72 23.1398C18.6068 23.1398 18.4952 23.1141 18.3934 23.0646C18.2917 23.0151 18.2025 22.9432 18.1326 22.8541C16.918 21.3011 14.24 17.6573 14.24 15.7459C14.2387 15.1572 14.3537 14.574 14.5783 14.0299C14.803 13.4858 15.133 12.9914 15.5492 12.5751C15.9655 12.1588 16.4599 11.8289 17.004 11.6042C17.5482 11.3796 18.1313 11.2646 18.72 11.2659V11.2659Z"
    ></path>
    <g>
      <path
        stroke="inherit"
        d="M18.725 16.1241C18.5188 16.1241 18.3517 15.957 18.3517 15.7508C18.3517 15.5446 18.5188 15.3774 18.725 15.3774"
      ></path>
      <path
        stroke="inherit"
        d="M18.7251 16.1241C18.9312 16.1241 19.0984 15.957 19.0984 15.7508C19.0984 15.5446 18.9312 15.3774 18.7251 15.3774"
      ></path>
    </g>
  </svg>
);
