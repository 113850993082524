import React, { ReactNode } from "react";
import { BrowserRouter } from 'react-router-dom';
import { TranslationLanguage, TranslationsProvider } from "@tour-buddy/ui-translations";
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SessionProvider } from './components/sessionProvider';
import { Pages } from './pages';
import { theme } from './theme';
import { LoginModalProvider } from './modals/loginModal';
import { RegisterModalProvider } from './modals/registerModal';
import { CreateUserPlanModalProvider } from './modals/createUserPlanModal';
import { NavBarDrawerProvider } from './components/navBarDrawer';
import { AuthProvider } from './components/authProvider';
import { SnackbarProvider } from 'notistack';
import { ApiProvider } from "@tour-buddy/ui-api-provider";
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from "./utils/localStorage";

const AppRoot = styled('div')`
  .SnackbarContainer-root {
    ${({ theme }) => theme.breakpoints.down('md')} {
      bottom: 64px;
    }
  }
`;

const ContextsProvider = ({ children }: { children: ReactNode }) => (
  <SessionProvider>
    <ApiProvider>
      <AuthProvider>
        <NavBarDrawerProvider>
          <CreateUserPlanModalProvider>
            <RegisterModalProvider>
              <LoginModalProvider>{children}</LoginModalProvider>
            </RegisterModalProvider>
          </CreateUserPlanModalProvider>
        </NavBarDrawerProvider>
      </AuthProvider>
    </ApiProvider>
  </SessionProvider>
);

const TranslatedApp = () => {
  const language = getLocalStorageItem(LOCAL_STORAGE_KEYS.LANGUAGE);

  return (
    <TranslationsProvider
      language={
        (language as TranslationLanguage) || TranslationLanguage.pl
      }
    >
      <Pages />
    </TranslationsProvider>
  );
};

const App = () => (
  <AppRoot>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} dense>
          <ContextsProvider>
            <CssBaseline />
            <TranslatedApp />
          </ContextsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </AppRoot>
);

export default App;
