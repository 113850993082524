import React from 'react';
import { Typography } from '@mui/material';
import { RegisterSuccessContainer, StyledSendEmailFlyIcon } from './styles';

export const ContentRegisterSuccess = () => {
  return (
    <RegisterSuccessContainer>
      <StyledSendEmailFlyIcon />
      <Typography variant="h3" component="h3">
        Check your inbox
      </Typography>
      <Typography variant="body1" component="p">
        We sent a verification link to your email
      </Typography>
    </RegisterSuccessContainer>
  );
};
