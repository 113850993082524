import { gql } from '@apollo/client';

export const LOGOUT_USER_MUTATION = gql`
  mutation LogoutUser($id: String!) {
    logout(input: { id: $id }) {
      statusCode
      code
      message
    }
  }
`;
