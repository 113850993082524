import { createTheme, responsiveFontSizes, Theme } from '@mui/material';

const palette = {
  primary: { main: '#FF5C57' },
  secondary: { main: '#36827F' },
  text: { primary: '#1E1E24' },
};

const typography: Partial<Theme['typography']> = {
  fontFamily: ['Rubik', 'sans-serif'].join(','),
  h1: {
    fontSize: 43,
    fontWeight: 700,
  },
  h2: {
    fontSize: 36,
    fontWeight: 600,
  },
  h3: {
    fontSize: 32,
    fontWeight: 600,
  },
  h4: {
    fontSize: 28,
    fontWeight: 600,
  },
  h5: {
    fontSize: 24,
    fontWeight: 600,
  },
  h6: {
    fontSize: 20,
    fontWeight: 600,
  },
  button: {
    fontSize: 16,
  },
  caption: {
    fontSize: 14,
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
          },
          outlined: {
            backgroundColor: 'white',
          },
          text: {
            fontWeight: '500',
          },
          contained: {
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':before': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  }),
);
