import React, { ChangeEventHandler, MouseEventHandler, useContext, useEffect, useState } from 'react';
import { Input, FormContainer, Header, StyledButton } from './styles';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// import { pageUrls } from '../urls';
import { ApiRequestContext } from '@tour-buddy/ui-api-provider';

const handleOnChange =
  (setMethod: (value: string) => void): ChangeEventHandler<HTMLInputElement> =>
  (e) =>
    setMethod(e.target.value);
const handleOnInputClick =
  (setMethod: any): MouseEventHandler =>
  () =>
    setMethod((prev: any) => (prev === undefined ? '' : prev));

export const ContentResetPassword = () => {
  // const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { resetPassword } = useContext(ApiRequestContext);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const forgotPasswordToken = searchParams.get('token');

  const handleSendButton = () => {
    resetPassword.request({ variables: { password, forgotPasswordToken } });
  };

  useEffect(() => {
    if (resetPassword.isFinished && resetPassword.data) {
      if (resetPassword.data?.statusCode === 200) {
        enqueueSnackbar('Password successfully updated');
        // navigate(`${pageUrls.main()}?login`);
      } else {
        enqueueSnackbar('Something went wrong');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassword.isFinished, resetPassword.data]);

  const isPasswordCorrect = password && confirmPassword && password === confirmPassword;

  return (
    <>
      <Header variant="h1">Reset password</Header>
      <FormContainer>
        <Input
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onClick={handleOnInputClick(setPassword)}
          onChange={handleOnChange(setPassword)}
          required
        />
        <Input
          label="Confirm password"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmPassword}
          onClick={handleOnInputClick(setConfirmPassword)}
          onChange={handleOnChange(setConfirmPassword)}
          required
        />
        <StyledButton variant="contained" size="large" onClick={handleSendButton} disabled={!isPasswordCorrect}>
          Reset password
        </StyledButton>
      </FormContainer>
    </>
  );
};
