import { gql } from '@apollo/client';

export const GET_MAP_PLACES_QUERY = gql`
  query Places($topLeftLat: String!, $topLeftLng: String!, $bottomRightLat: String!, $bottomRightLng: String!, $tags: [String!]) {
    places(
      tags: $tags
      locationArea: {
        topLeft: { latitude: $topLeftLat, longitude: $topLeftLng }
        bottomRight: { latitude: $bottomRightLat, longitude: $bottomRightLng }
      }
      sort: "ranking:asc"
    ) {
      statusCode
      code
      message
      data {
        id
        name
        location {
          latitude
          longitude
        }
        ranking {
          overallScore
          dataScore
        }
        images(limit: 1) {
          link
          thumbnailLink
          alt
        }
      }
    }
  }
`;
