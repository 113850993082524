import React from 'react';
import { NavBarContent, NavBarHeader, StyledCloseNavigationMenuIcon, SubNavBarContainer } from './styles';
import { SubNavBarProps } from './types';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';

const CLOSE_STATIC_TEXT = 'Close';

export const SubNavBar = ({ isOpen, mainNavBarIsOpen, title, children, onClose }: SubNavBarProps) => {
  return (
    <SubNavBarContainer open={isOpen} mainNavBarIsOpen={mainNavBarIsOpen} variant="permanent" onClose={onClose}>
      <NavBarHeader />
      <NavBarContent>
        <List>
          <ListItem disablePadding className="navbar-toggle">
            <ListItemButton onClick={() => onClose()}>
              <ListItemIcon>
                <StyledCloseNavigationMenuIcon />
              </ListItemIcon>
              {`${CLOSE_STATIC_TEXT} ${title}`}
            </ListItemButton>
          </ListItem>
          {children}
        </List>
      </NavBarContent>
    </SubNavBarContainer>
  );
};
