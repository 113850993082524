import { gql } from '@apollo/client';

export const UPDATE_PLACE_USER_PLANS_MUTATION = gql`
  mutation UpdatePlaceUserPlans($placeId: String!, $userPlansIds: [String!]!) {
    updatePlaceUserPlans(input: { placeId: $placeId, userPlansIds: $userPlansIds }) {
      data {
        id
        name
        placeIds
      }
      statusCode
      code
      message
    }
  }
`;
