import React from 'react';
import { Header, StyledButton } from './styles';
import { useNavigate } from 'react-router-dom';
import { pageUrls } from '../urls';
import { StyledCheckCircleIcon } from "../verifyAccount/styles";
import { Typography } from '@mui/material';

export const ContentResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const handleGoBackClick = () => {
    navigate(pageUrls.main());
  };

  return (
    <>
      <StyledCheckCircleIcon />
      <Header variant="h1">Reset password</Header>
      <Typography variant="body1">Password successfully updated</Typography>
      <StyledButton variant="contained" onClick={handleGoBackClick}>
        Go back
      </StyledButton>
    </>
  );
};
