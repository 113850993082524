import React, { useContext, useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LoginModalProps } from './types';
import { Container, Content } from './styles';
import { LoginModalOpenContext } from './loginModalProvider';
import { ContentLogin } from './contentLogin';
import { ContentForgotPassword } from './contentForgotPassword';
import { ContentForgotPasswordSuccess } from './contentForgotPasswordSuccess';
import { RegisterModalOpenContext } from '../registerModal';

enum CONTENT_TYPE {
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
}

export const LoginModal: React.FC<LoginModalProps> = () => {
  const [searchParams] = useSearchParams();
  const { isOpen, toggleIsOpen } = useContext(LoginModalOpenContext);
  const { toggleIsOpen: toggleRegisterModalOpen } = useContext(RegisterModalOpenContext);
  const [contentType, setContentType] = useState(CONTENT_TYPE.LOGIN);

  useEffect(() => {
    const shouldOpenOnLoad = searchParams.get('login');
    if (shouldOpenOnLoad === '') toggleIsOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleClose = () => {
    setContentType(CONTENT_TYPE.LOGIN);
    toggleIsOpen();
  };

  const handleForgotPasswordClick = () => {
    setContentType(CONTENT_TYPE.FORGOT_PASSWORD);
  };

  const handleForgotPasswordSuccessClick = () => {
    setContentType(CONTENT_TYPE.FORGOT_PASSWORD_SUCCESS);
  };

  const handleBackToLoginClick = () => {
    setContentType(CONTENT_TYPE.LOGIN);
  };

  const handleRegisterClick = () => {
    toggleIsOpen();
    toggleRegisterModalOpen();
  };

  return (
    <Container open={isOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Content>
          {contentType === CONTENT_TYPE.LOGIN && (
            <ContentLogin
              onClose={handleClose}
              onForgotPassword={handleForgotPasswordClick}
              onRegisterClick={handleRegisterClick}
            />
          )}
          {contentType === CONTENT_TYPE.FORGOT_PASSWORD && (
            <ContentForgotPassword onBack={handleBackToLoginClick} onSuccess={handleForgotPasswordSuccessClick} />
          )}
          {contentType === CONTENT_TYPE.FORGOT_PASSWORD_SUCCESS && (
            <ContentForgotPasswordSuccess onBack={handleBackToLoginClick} />
          )}
        </Content>
      </Fade>
    </Container>
  );
};
