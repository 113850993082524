import React, { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
  ClearSearchIcon,
  ClearSearchIconContainer,
  Container,
  SearchBarContainer,
  SearchInput,
  StyledSearchIcon,
  SuggestionsTransition,
} from './styles';
import { Suggestions } from './suggestions';
import { useClickOutside } from '../../hooks/useClickAway';
import { useLazyQuery } from '@apollo/client';
import { GET_PLACES_QUERY } from './queries';

interface SearchBarProps {
  value?: string | null;
  placeholder?: string;
  onSearchChange?: (value: string) => void;
  showMobile: boolean;
}

export const SearchBar = ({ value, placeholder, onSearchChange, showMobile }: SearchBarProps) => {
  const [getPlaces, { data: placesData }] = useLazyQuery(GET_PLACES_QUERY);
  const [searchValue, setSearchValue] = useState(value);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const SuggestionsContainerRef = useRef(null);
  const ContainerRef = useRef(null);

  const handleSearchClickOutside = () => {
    setShowSuggestions(false);
  };

  useClickOutside(ContainerRef, handleSearchClickOutside);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    if (placesData) {
      setSuggestions(placesData.places);
    }
  }, [placesData]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    if (value !== '') {
      setSearchValue(event.target.value);
      getPlaces({ variables: { q: value } });
      setShowSuggestions(true);
      if (onSearchChange) onSearchChange(value);
    } else {
      handleSearchClear();
    }
  };

  const handleSearchClick: MouseEventHandler = () => {
    setShowSuggestions(true);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    setShowSuggestions(false);
  };

  return (
    <Container ref={ContainerRef} showMobile={showMobile}>
      <SearchBarContainer>
        <StyledSearchIcon />
        <SearchInput
          value={searchValue}
          defaultValue={value}
          placeholder={placeholder || 'Search in Wizytor'}
          onChange={handleSearchChange}
          onClick={handleSearchClick}
        />
        <ClearSearchIconContainer onClick={handleSearchClear} searchValue={searchValue}>
          <ClearSearchIcon />
        </ClearSearchIconContainer>
      </SearchBarContainer>
      {searchValue && (
        <SuggestionsTransition
          nodeRef={SuggestionsContainerRef}
          timeout={300}
          in={showSuggestions}
          classNames="suggestions-transition"
          unmountOnExit
        >
          <Suggestions
            ref={SuggestionsContainerRef}
            values={suggestions}
            searchValue={searchValue}
            onItemSelect={handleSearchClickOutside}
          />
        </SuggestionsTransition>
      )}
    </Container>
  );
};
