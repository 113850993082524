import { styled } from '@mui/material/styles';
import { IconButton, InputBase, ListItemButton } from '@mui/material';
import { CloseIcon, SearchIcon } from '../icons';
import { CSSTransition } from 'react-transition-group';

export const Container = styled('div')<{ showMobile: boolean }>`
  display: ${({ showMobile }) => (showMobile ? 'flex' : 'none')};
  flex-flow: column;
  justify-content: stretch;
  width: calc(100% - 8px);

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    width: 50%;
    max-width: calc(700px - 2 * 24px);
  }
`;

export const ClearSearchIconContainer = styled(IconButton, {
  shouldForwardProp: (propName: string) => !['searchValue'].includes(propName),
})<{ searchValue?: string | null }>`
  visibility: ${({ searchValue }) => (searchValue ? 'visible' : 'hidden')};
  margin-left: -50px;
  z-index: 2;
  left: -8px;
`;

export const ClearSearchIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  stroke: ${({ theme }) => theme.palette.grey[600]};
  transition: all 300ms;
  cursor: pointer;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 16px;
  height: 16px;
  stroke: ${({ theme }) => theme.palette.grey[600]};
  margin-right: -50px;
  z-index: 2;
`;

export const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  margin: 8px 0;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  ${({ theme }) => theme.breakpoints.down('md')} {
  }
`;

export const SearchInput = styled(InputBase)`
  cursor: pointer;
  padding: 8px 8px 8px 50px;
  margin: 0 16px;
  z-index: 1;
  width: ${({ value }) => (value ? 'calc(100% - 32px)' : '250px')};
  transition: 300ms all;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.grey[200]};

  &.Mui-focused {
    width: calc(100% - 32px);
  }
`;

export const SuggestionsContainer = styled('div')<{ valuesCount: number }>`
  background-color: white;
  width: calc(100% - 32px);
  height: ${({ valuesCount }) => (valuesCount ? 'auto' : '150px')};
  border-radius: 0 0 ${({ theme }) => theme.shape.borderRadius}px ${({ theme }) => theme.shape.borderRadius}px;
  display: flex;
  flex-flow: column;
  align-self: flex-end;
  justify-content: center;
  align-items: ${({ valuesCount }) => (valuesCount ? 'flex-start' : 'center')};
  transition: height 500ms;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border: 1px solid lightgray;
  }
`;

export const SuggestionsTransition = styled(CSSTransition)(
  ({ theme }) => `
  &.suggestions-transition-enter {
    height: 0;
  }
  &.suggestions-transition-enter-active {
    height: 150px;
    transition: height 300ms;
  }

  &.suggestions-transition-exit {
    height: 150px;
  }

  &.suggestions-transition-exit-active {
    height: 0;
    transition: height 300ms;
  }  
`,
);

export const SuggestionItemButton = styled(ListItemButton)`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;
