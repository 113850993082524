import { gql } from '@apollo/client';

export const GET_SELECTED_USER_PLAN_QUERY = gql`
  query UserPlan($userPlanId: ID!) {
    userPlan(id: $userPlanId) {
      statusCode
      code
      message
      data {
        id
        name
        placeIds
        places {
          id
          name
          ranking {
            overallScore
            dataScore
          }
          location {
            latitude
            longitude
            country
            city
            address
            administrativeArea1
            administrativeArea2
            administrativeArea3
            postalCode
          }
          images {
            link
            alt
          }
        }
      }
    }
  }
`;
