import { css, styled, Theme } from "@mui/material/styles";
import { Drawer } from '@mui/material';
import { NavigationMenuIcon, KeyboardArrowReturnIcon } from '../icons';

const DRAWER_WIDTH_OPEN = 240;
const DRAWER_WIDTH_CLOSE = 64;

const SUBNAVBAR_DRAWER_WIDTH_OPEN = 240;
const SUBNAVBAR_DRAWER_WIDTH_CLOSE = 0;

const DRAWER_HEIGHT_OPEN = 200;
const DRAWER_HEIGHT_CLOSE = 0;

const openedMixin = (theme: Theme, size: string) => `
  overflow-x: hidden;

  ${theme.breakpoints.up('md')} {
    width: ${size};
    transition: ${theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
  }
  
  ${theme.breakpoints.down('md')} {
    height: ${size};
    transition: ${theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
    width: 100%;
  }
`;

const closedMixin = (theme: Theme, size: string) => `
  overflow-x: hidden;
  
  ${theme.breakpoints.up('md')} {
    width: ${size};
    transition: ${theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    })};
  }
  
  ${theme.breakpoints.down('md')} {
    height: ${size};
    width: 100%;
    transition: ${theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    })};
  }
`;

export const Container = styled(Drawer)`
  width: ${({ open }) => (open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE)}px;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: ${({ open }) => (open ? '100%' : 0)};
    ${({ open, theme }) =>
      open ? openedMixin(theme, `${DRAWER_HEIGHT_OPEN}px`) : closedMixin(theme, `${DRAWER_HEIGHT_CLOSE}`)}
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ open, theme }) =>
      open ? openedMixin(theme, `${DRAWER_WIDTH_OPEN}px`) : closedMixin(theme, `${DRAWER_WIDTH_CLOSE}px`)}
  }
  

  & .MuiDrawer-paper {
    border-right: 0px solid transparent;

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: ${({ open }) => (open ? '100%' : 0)};
      ${({ open, theme }) =>
        open ? openedMixin(theme, `${DRAWER_HEIGHT_OPEN}px`) : closedMixin(theme, `${DRAWER_HEIGHT_CLOSE}`)}
    }
    ${({ theme }) => theme.breakpoints.up('md')} {
      ${({ open, theme }) =>
        open ? openedMixin(theme, `${DRAWER_WIDTH_OPEN}px`) : closedMixin(theme, `${DRAWER_WIDTH_CLOSE}px`)}
    }
  }

  .navbar-icon {
    width: 32px;
    height: 32px;
    stroke: ${({ theme }) => theme.palette.grey.A700};
  }

  .navbar-toggle {
    margin-bottom: 16px;
  }
`;

export const SubNavBarContainer = styled(Drawer, {
  shouldForwardProp: (propName: string) => !['mainNavBarIsOpen'].includes(propName),
})<{ mainNavBarIsOpen: boolean }>`
  margin-left: ${({ mainNavBarIsOpen }) => (mainNavBarIsOpen ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE)}px;
  width: ${({ open }) => (open ? `${DRAWER_WIDTH_OPEN}px` : 0)};
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({ open, theme }) => (open ? openedMixin(theme, `${SUBNAVBAR_DRAWER_WIDTH_OPEN}px`) : closedMixin(theme, `${SUBNAVBAR_DRAWER_WIDTH_CLOSE}`))}

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ open }) => open ? css`
      margin-left: 0;
      height: 200px;
    ` : ''
    }
  }

  & .MuiDrawer-paper {
    margin-left: ${({ mainNavBarIsOpen }) => (mainNavBarIsOpen ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE)}px;
    border-right: 0px solid transparent;
    ${({ open, theme }) => (open ? openedMixin(theme, `${SUBNAVBAR_DRAWER_WIDTH_OPEN}px`) : closedMixin(theme, `${SUBNAVBAR_DRAWER_WIDTH_CLOSE}`))}

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 0;
      top: auto;
      bottom: 56px;
    }
  }

  .navbar-icon {
    width: 32px;
    height: 32px;
    stroke: ${({ theme }) => theme.palette.grey.A700};
  }

  .navbar-toggle {
    margin-bottom: 16px;
  }
`;

export const NavBarHeader = styled('div')`
  height: 60px;
  width: ${DRAWER_WIDTH_OPEN}px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const StyledNavigationMenuIcon = styled(NavigationMenuIcon)`
  stroke: ${({ theme }) => theme.palette.grey.A700};
  width: 32px;
  height: 32px;
`;

export const StyledCloseNavigationMenuIcon = styled(KeyboardArrowReturnIcon)`
  stroke: ${({ theme }) => theme.palette.grey.A700};
  width: 32px;
  height: 32px;
`;

export const NavBarContent = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
`;

export const NavBarMobileBar = styled('div')`
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  overflow-y: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: initial;
  }

  .navbar-icon {
    width: 32px;
    height: 32px;
    stroke: ${({ theme }) => theme.palette.grey.A700};
  }

  button.Mui-selected {
    color: ${({ theme }) => theme.palette.secondary.main};

    .navbar-icon {
      stroke: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
