import React from 'react';
import { Header, StyledButton } from './styles';
import { useNavigate } from 'react-router-dom';
import { pageUrls } from '../urls';
import { StyledAlertCircleIcon } from '../verifyAccount/styles';
import { Typography } from '@mui/material';

export const ContentResetPasswordError = () => {
  const navigate = useNavigate();
  const handleGoBackClick = () => {
    navigate(pageUrls.main());
  };

  return (
    <>
      <StyledAlertCircleIcon />
      <Header variant="h1">Reset password</Header>
      <Typography variant="body1">Something went wrong</Typography>
      <StyledButton variant="contained" onClick={handleGoBackClick}>
        Go back
      </StyledButton>
    </>
  );
};
