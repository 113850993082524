import React, { ChangeEvent, useContext } from "react";
import {
  Container,
  Content,
  Description,
  FeatureColumn,
  FeatureGroup,
  FeatureRow,
  FeatureTitle,
  MainAppImage,
  MainContainer,
  MainImageContainer,
  MainTextContainer,
  NewsletterButton,
  NewsletterInput,
  NumberGroup,
  NumberList,
  NumberText,
  NumberValue,
  Row,
  Section,
  StoreImg
} from "./styles";
import { Typography } from "@mui/material";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { UseInputProps, useInputString } from "../../hooks/useInputString";
import { TranslationKey, useTranslation } from "@tour-buddy/ui-translations";

export const emailInputProps: UseInputProps = {
  type: 'email',
  validation: [
    {
      message: 'Invalid email',
      pattern:
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    },
  ],
};

const Newsletter = () => {
  const inputText = useTranslation(TranslationKey['main.landingPage.newsletter.input']);
  const inputLabelText = useTranslation(TranslationKey['main.landingPage.newsletter.inputLabel']);
  const buttonText = useTranslation(TranslationKey['main.landingPage.newsletter.button']);
  const { subscribeNewsletter } = useContext(ApiRequestContext);
  const email = useInputString(emailInputProps);

  const handleNewsletterSubscribe = () => {
    if (!email.error) {
      subscribeNewsletter.request({ variables: { email: email.value } });
      email.onChange({ target: { value: null } } as any as ChangeEvent<HTMLInputElement>);
    }

  };
  return (
    <Row forceRow>
      <NewsletterInput placeholder={inputText} label={inputLabelText} {...email} />
      <NewsletterButton variant="contained" onClick={handleNewsletterSubscribe}>{buttonText}</NewsletterButton>
    </Row>
  );

};

const StoreImage = ({ imageUrl, link, alt }: { imageUrl: string; link: string; alt: string }) => (
  <a href={link}>
    <StoreImg alt={alt} src={imageUrl} />
  </a>
);

const MainSection = () => {
  const headerText = useTranslation(TranslationKey['main.landingPage.main.header']);
  const descriptionText = useTranslation(TranslationKey['main.landingPage.main.description']);

  return (
    <Content>
      <MainContainer>
        <MainTextContainer>
          <Typography variant="h1">{headerText}</Typography>
          <Typography variant="body1">{descriptionText}</Typography>
          <Row>
            <StoreImage alt="google play" imageUrl="google-play-badge-en.png" link="https://play.google.com/store/apps/details?id=com.wizytor" />
            {/*<StoreImage alt="apple store" />*/}
          </Row>
        </MainTextContainer>
        <MainImageContainer>
          <MainAppImage src="app_screenshot.png" />
        </MainImageContainer>
      </MainContainer>
    </Content>
  );
}

const NumbersSection = () => {
  const headerText = useTranslation(TranslationKey['main.landingPage.numbers.header']);
  const paragraphText = useTranslation(TranslationKey['main.landingPage.numbers.paragraph']);
  const cityTitleText = useTranslation(TranslationKey['main.landingPage.numbers.cityTitle']);
  const cityNumberText = useTranslation(TranslationKey['main.landingPage.numbers.cityNumber']);
  const countryTitleText = useTranslation(TranslationKey['main.landingPage.numbers.countryTitle']);
  const countryNumberText = useTranslation(TranslationKey['main.landingPage.numbers.countryNumber']);
  const placesNumberText = useTranslation(TranslationKey['main.landingPage.numbers.placesNumber']);
  const placesTitleText = useTranslation(TranslationKey['main.landingPage.numbers.placesTitle']);

  return (
    <Content>
      <Typography variant="h2">{headerText}</Typography>
      <NumberList>
        <NumberGroup>
          <NumberValue variant="body1">{cityNumberText}</NumberValue>
          <NumberText variant="body1">{cityTitleText}</NumberText>
        </NumberGroup>
        <NumberGroup>
          <NumberValue variant="body1">{countryNumberText}</NumberValue>
          <NumberText variant="body1">{countryTitleText}</NumberText>
        </NumberGroup>
        <NumberGroup>
          <NumberValue variant="body1">{placesNumberText}</NumberValue>
          <NumberText variant="body1">{placesTitleText}</NumberText>
        </NumberGroup>
      </NumberList>
      <Description variant="h4">{paragraphText}</Description>
    </Content>
  );
}

const FeaturesSection = () => {
  const headerText = useTranslation(TranslationKey['main.landingPage.features.header']);
  const feature1HeaderText = useTranslation(TranslationKey['main.landingPage.features.feature1Header']);
  const feature1DescriptionText = useTranslation(TranslationKey['main.landingPage.features.feature1Description']);
  const feature2HeaderText = useTranslation(TranslationKey['main.landingPage.features.feature2Header']);
  const feature2DescriptionText = useTranslation(TranslationKey['main.landingPage.features.feature2Description']);
  const feature3HeaderText = useTranslation(TranslationKey['main.landingPage.features.feature3Header']);
  const feature3DescriptionText = useTranslation(TranslationKey['main.landingPage.features.feature3Description']);
  const feature4HeaderText = useTranslation(TranslationKey['main.landingPage.features.feature4Header']);
  const feature4DescriptionText = useTranslation(TranslationKey['main.landingPage.features.feature4Description']);

  return (
    <Content>
      <FeatureTitle variant="h2">{headerText}</FeatureTitle>
      <FeatureColumn>
        <FeatureRow>
          <FeatureGroup>
            <Typography variant="h6">{feature1HeaderText}</Typography>
            <Typography variant="body1">{feature1DescriptionText}</Typography>
          </FeatureGroup>
          <FeatureGroup>
            <Typography variant="h6">{feature2HeaderText}</Typography>
            <Typography variant="body1">{feature2DescriptionText}</Typography>
          </FeatureGroup>
        </FeatureRow>
        <FeatureRow>
          <FeatureGroup>
            <Typography variant="h6">{feature3HeaderText}</Typography>
            <Typography variant="body1">{feature3DescriptionText}</Typography>
          </FeatureGroup>
          <FeatureGroup>
            <Typography variant="h6">{feature4HeaderText}</Typography>
            <Typography variant="body1">{feature4DescriptionText}</Typography>
          </FeatureGroup>
        </FeatureRow>
      </FeatureColumn>
    </Content>
  );
}

const NewsletterSection = () => {
  const h1Text = useTranslation(TranslationKey['main.landingPage.newsletter.header']);
  const h2Text = useTranslation(TranslationKey['main.landingPage.newsletter.h2']);
  return (
    <Content>
      <Typography variant="h2">{h1Text}</Typography>
      <Typography variant="h4" style={{ fontWeight: 400 }}>{h2Text}</Typography>
      <Newsletter />
    </Content>
  );
}

export const LandingPage = () => {
  return (
    <Container>
      <Section>
        <MainSection />
      </Section>
      <Section isColorBackground>
        <NewsletterSection />
      </Section>
      <Section>
        <NumbersSection />
      </Section>
      <Section isColorBackground>
        <FeaturesSection />
      </Section>
      <Section>
        <NewsletterSection />
      </Section>
    </Container>
  );
};
