import { gql } from '@apollo/client';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($forgotPasswordToken: String!, $password: String!) {
    resetPassword(input: { forgotPasswordToken: $forgotPasswordToken, password: $password }) {
      data
      statusCode
      code
      message
    }
  }
`;
