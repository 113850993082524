import React, { useState } from 'react';
import { SearchMobileProps } from './types';
import { SearchContainerMobile, SearchIconMobile } from './styles';
import SearchIcon from '@mui/icons-material/Search';
import CrossIcon from '@mui/icons-material/Close';
import { SearchBar } from '../searchBar';

export const SearchMobile: React.FC<SearchMobileProps> = ({ onSearchChange, searchValue }) => {
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch((prev) => !prev);
  };

  return (
    <>
      <SearchIconMobile onClick={toggleSearch}>
        {showSearch ? <CrossIcon fontSize="large" /> : <SearchIcon fontSize="large" />}
      </SearchIconMobile>
      {showSearch && (
        <SearchContainerMobile>
          <SearchBar value={searchValue} onSearchChange={onSearchChange} showMobile />
        </SearchContainerMobile>
      )}
    </>
  );
};
