import { gql } from '@apollo/client';

export const UPDATE_USER_PLAN_MUTATION = gql`
  mutation UpdateUserPlan($id: String!, $placeIds: [String!]) {
    updateUserPlan(input: { id: $id, placeIds: $placeIds }) {
      data {
        id
        name
        placeIds
      }
      statusCode
      code
      message
    }
  }
`;
