import { gql } from '@apollo/client';

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($userId: String!, $oldPassword: String!, $newPassword: String!) {
    updateUser(input: { id: $userId, oldPassword: $oldPassword, newPassword: $newPassword }) {
      data {
        id
        email
        firstName
        lastName
      }
      statusCode
      code
      message
    }
  }
`;
