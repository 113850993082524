import React, { ReactNode, useState } from 'react';
import { RegisterModal } from './registerModal';

export const RegisterModalOpenContext = React.createContext({ isOpen: false, toggleIsOpen: () => {} });

export const RegisterModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <RegisterModalOpenContext.Provider value={{ isOpen, toggleIsOpen }}>
      {children}
      <RegisterModal />
    </RegisterModalOpenContext.Provider>
  );
};
