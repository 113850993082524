import React, { useState } from 'react';
import { NavBarProps } from './types';

export const NavBarOpenContext = React.createContext({
  navbarIsOpen: false,
  toggleNavbarIsOpen: (value?: boolean) => {},
  subNavbarIsOpen: false,
  toggleSubNavbarIsOpen: (value?: boolean) => {},
});

export const NavBarDrawerProvider: React.FC<NavBarProps> = ({ children }) => {
  const [navbarIsOpen, setNavbarIsOpen] = useState(false);
  const [subNavbarIsOpen, setSubNavbarIsOpen] = useState(false);

  const toggleNavbarIsOpen = (value?: boolean) => {
    if (value !== undefined) setNavbarIsOpen(value);
    else setNavbarIsOpen((prevState) => !prevState);
  };

  const toggleSubNavbarIsOpen = (value?: boolean) => {
    if (value !== undefined) setSubNavbarIsOpen(value);
    else setSubNavbarIsOpen((prevState) => !prevState);
  };

  return (
    <NavBarOpenContext.Provider value={{ navbarIsOpen, toggleNavbarIsOpen, subNavbarIsOpen, toggleSubNavbarIsOpen }}>
      {children}
    </NavBarOpenContext.Provider>
  );
};
