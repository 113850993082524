import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItem, Typography } from '@mui/material';
import { SuggestionItemButton, SuggestionsContainer } from './styles';
import { pageUrls } from '../../pages/urls';
import { useTrackingComponentView, useTrackingUserAction } from '../../hooks/useTracking';
import { SuggestionItemProps, SuggestionsProps } from './types';

const SuggestionItem = ({ id, name, location, trackingData, onItemSelect }: SuggestionItemProps) => {
  const { trackUserAction, trackingActionTypes } = useTrackingUserAction();
  const navigate = useNavigate();

  const formatAddress = () => {
    const basicAddress = [location?.address, location?.city, location?.country].filter((v) => v);
    if (basicAddress.length) return basicAddress.join(', ');
    const coordinations = [location?.country, location?.latitude, location?.longitude].filter((v) => v);
    if (coordinations.length) return coordinations.join(', ');
    return 'no location details';
  };

  const handleClick = () => {
    trackUserAction({
      componentName: 'Search_Suggestions_Item',
      componentPayload: { id, name },
      actionType: trackingActionTypes.click,
      ...trackingData,
    });
    navigate(pageUrls.placeDetails(id));
    if (onItemSelect) onItemSelect();
  };

  return (
    <ListItem disablePadding>
      <SuggestionItemButton onClick={handleClick}>
        <Typography variant="subtitle1" color="text.primary">
          <b>{name}</b>
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {formatAddress()}
        </Typography>
      </SuggestionItemButton>
    </ListItem>
  );
};

export const Suggestions = ({ ref, values, searchValue, onItemSelect }: SuggestionsProps) => {
  useTrackingComponentView({
    ref,
    componentName: 'Search_Suggestions_List',
    componentPayload: { searchValue, values },
  });
  return (
    <SuggestionsContainer ref={ref} valuesCount={values.length}>
      {values.length ? (
        values
          .slice(0, 5)
          .map((value, idx) => (
            <SuggestionItem
              {...value}
              onItemSelect={onItemSelect}
              trackingData={{ listCount: values.length, listPosition: idx, isList: true }}
            />
          ))
      ) : (
        <Typography variant="body1" color="text.secondary">
          No places found with <b>'{searchValue}'</b>
        </Typography>
      )}
    </SuggestionsContainer>
  );
};
