import React, { useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

import { ResetPassword } from './resetPassword';
import { VerifyAccount } from './verifyAccount';
import { PrivacyPolicy } from './privacyPolicy';
import { RemoveAccount } from './removeAccount';
import { TermsAndConditions } from './termsAndConditions';
// import { Main } from './main';
// import { PlacesMap } from './placesMap';
// import { AddFact } from './addFact';
// import { AddPhoto } from './addPhoto';
// import { UpdatePassword } from './updatePassword';
// import { UserAccount } from './userAccount';
import { LandingPage } from './landingPage';
import { pageUrls } from './urls';
import { Layout } from '../components/layout';

interface SetLayoutConfigProps {
  enableSearch: boolean;
  hideDrawer: boolean;
}
export const Pages = () => {
  const [enableSearch, setEnableSearch] = useState(false);
  const [hideDrawer, setHideDrawer] = useState(true);

  const setLayoutConfig = (props: SetLayoutConfigProps) => {
    setEnableSearch(props.enableSearch ?? true);
    setHideDrawer(props.hideDrawer ?? false);
    return null;
  };

  return (
    <Layout enableSearch={enableSearch} hideDrawer={hideDrawer}>
      <Routes>
        <Route
          path={pageUrls.landingPage()}
          element={<LandingPage />}
          loader={() => {
            setLayoutConfig({ enableSearch: false, hideDrawer: true });
            return {};
          }}
        />
        <Route
          path={pageUrls.privacyPolicy()}
          element={<PrivacyPolicy />}
          loader={() => {
            setLayoutConfig({ enableSearch: false, hideDrawer: true });
            return {};
          }}
        />
        <Route
          path={pageUrls.termsAndConditions()}
          element={<TermsAndConditions />}
          loader={() => {
            setLayoutConfig({ enableSearch: false, hideDrawer: true });
            return {};
          }}
        />
        <Route
          path={pageUrls.removeAccount()}
          element={<RemoveAccount />}
          loader={() => {
            setLayoutConfig({ enableSearch: false, hideDrawer: true });
            return {};
          }}
        />
        <Route
          path={pageUrls.verifyAccount()}
          element={<VerifyAccount />}
          loader={() => {
            setLayoutConfig({ enableSearch: false, hideDrawer: true });
            return {};
          }}
        />
       <Route
         path={pageUrls.resetPassword()}
         element={<ResetPassword />}
         loader={() =>
           setLayoutConfig({
             enableSearch: false,
             hideDrawer: true,
           })
         }
       />
        <Route
          path="*"
         element={(
            <Navigate to={pageUrls.landingPage()} replace={true} />
          )}
        />
      </Routes>
    </Layout>
  );
};

//<Route
//           path={pageUrls.planPlaceDetails()}
//           element={<PlacesMap />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.placeDetails()}
//           element={<PlacesMap />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.planDetails()}
//           element={<PlacesMap />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.map()}
//           element={<PlacesMap />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.main()}
//           element={<Main />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.addFact()}
//           element={<AddFact />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: false,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.addPhoto()}
//           element={<AddPhoto />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: false,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.updatePassword()}
//           element={<UpdatePassword />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
//         <Route
//           path={pageUrls.userAccount()}
//           element={<UserAccount />}
//           loader={() =>
//             setLayoutConfig({
//               enableSearch: true,
//               hideDrawer: false,
//             })
//           }
//         />
