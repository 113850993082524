export const pageUrls = {
  landingPage: () => '/landing',
  privacyPolicy: () => '/privacy_policy',
  termsAndConditions: () => '/terms_and_conditions',
  removeAccount: () => '/remove_account',
  main: () => '/',
  map: () => '/map',
  planPlaceDetails: (userPlanId = ':userPlanId', placeId = ':placeId') => `/place/${placeId}?planId=${userPlanId}`,
  placeDetails: (placeId = ':placeId') => `/place/${placeId}`,
  planDetails: (userPlanId = ':userPlanId') => `/plan/${userPlanId}`,
  resetPassword: () => '/reset-password',
  updatePassword: () => '/update-password',
  verifyAccount: () => '/verify-account',
  userAccount: () => '/user-account',
  addFact: (placeId = ':placeId') => `/place/${placeId}/new-fact`,
  addPhoto: (placeId = ':placeId') => `/place/${placeId}/new-photo`,
};
