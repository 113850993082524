import React, { ReactNode, useState } from 'react';
import { CreateUserPlanModal } from './createUserPlanModal';

export const CreateUserPlanModalOpenContext = React.createContext({ isOpen: false, toggleIsOpen: () => {} });

export const CreateUserPlanModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <CreateUserPlanModalOpenContext.Provider value={{ isOpen, toggleIsOpen }}>
      {children}
      <CreateUserPlanModal />
    </CreateUserPlanModalOpenContext.Provider>
  );
};
