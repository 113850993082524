import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const DRAWER_WIDTH_OPEN = 240;
const DRAWER_WIDTH_CLOSE = 64;

export const Container = styled(Box)`
  margin: 0;
`;

export const ContentContainer = styled(Box, {
  shouldForwardProp: (propName: string) => !['hideDrawer', 'isDrawerOpen'].includes(propName),
})<{ hideDrawer: boolean; isDrawerOpen: boolean }>`
  flex-grow: 1;
  width: ${({ isDrawerOpen, hideDrawer }) => hideDrawer ? '100%' : `calc(100% - 64px${isDrawerOpen ? ` - ${DRAWER_WIDTH_OPEN}px` : ''})`};
  ${({ isDrawerOpen, theme }) => {
    if (isDrawerOpen) {
      return `
        transition: ${theme.transitions.create(['margin-left', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })};
      `;
    }
    return `
      transition: ${theme.transitions.create(['margin-left', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })};
    `;
  }}
  margin-left: ${({ hideDrawer, isDrawerOpen }) => {
    if (hideDrawer) return 0;
    if (isDrawerOpen) {
      return `${DRAWER_WIDTH_OPEN}px`;
    }
    return `${DRAWER_WIDTH_CLOSE}px`;
  }};

  ${({ theme }) => theme.breakpoints.down('md')} {
    //margin-left: 8px;
    //width: calc(100% - 16px);
  }
`;
