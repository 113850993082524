import { UseInputProps, UseInputResponse } from '../../hooks/useInputString';

export const emailInputProps: UseInputProps = {
  type: 'email',
  required: true,
  validation: [
    {
      message: 'Invalid email',
      pattern:
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    },
  ],
};

export const lastNameInputProps: UseInputProps = {
  required: true,
  validation: [
    {
      message: 'Last name too short',
      minLength: 2,
    },
  ],
};

export const firstNameInputProps: UseInputProps = {
  required: true,
  validation: [
    {
      message: 'First name too short',
      minLength: 2,
    },
  ],
};

export const passwordInputProps: UseInputProps = {
  type: 'password',
  required: true,
  validation: [
    {
      message: 'At least one number',
      pattern: '[0-9]+',
    },
    {
      message: 'At least one capital letter',
      pattern: '[A-Z]+',
    },
    {
      message: 'At least one lowercase letter',
      pattern: '[a-z]+',
    },
    {
      message: 'At least 8 characters',
      minLength: 8,
    },
  ],
};

export const passwordConfirmInputProps = ({ password }: { password: UseInputResponse }): UseInputProps => ({
  type: 'password',
  required: true,
  validation: [
    {
      message: 'Password does not match',
      check: (value) => value !== password.value,
    },
  ],
});
