import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Constants } from '@tour-buddy/interfaces';
import { ADD_TRACKING_MUTATION } from './queries';
import { getUrl } from './utils';
import { TrackingEventPageView } from './types';
import { useSession } from '../useSession';

const SKIP = true;

export const useTrackingPageView = () => {
  const [sendTrackingRequest] = useMutation(ADD_TRACKING_MUTATION);
  const { sessionId } = useSession();

  const url = getUrl();

  const trackPageView = () => {
    if (SKIP) return;

    const trackingEvent: TrackingEventPageView = {
      type: Constants.TRACKING_EVENT_TYPES.pageView,
      sessionId: sessionId as string,
      payload: {
        url,
      },
    };

    sendTrackingRequest({
      variables: {
        type: trackingEvent.type,
        sessionId: trackingEvent.sessionId,
        url: trackingEvent.payload.url,
      },
    });
  };

  useEffect(() => {
    if (sessionId) {
      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
