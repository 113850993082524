import React from 'react';
import { IconProps } from './types';

export const TripDestinationIcon = ({ className, style }: IconProps) => (
  <svg
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.25 4.5C23.25 6.2 20.713 10.178 19.813 11.536C19.7788 11.5876 19.7324 11.6299 19.6778 11.6591C19.6233 11.6884 19.5624 11.7037 19.5005 11.7037C19.4386 11.7037 19.3777 11.6884 19.3232 11.6591C19.2686 11.6299 19.2222 11.5876 19.188 11.536C18.287 10.179 15.75 6.2 15.75 4.5C15.75 3.50544 16.1451 2.55161 16.8483 1.84835C17.5516 1.14509 18.5054 0.75 19.5 0.75C20.4946 0.75 21.4484 1.14509 22.1517 1.84835C22.8549 2.55161 23.25 3.50544 23.25 4.5V4.5Z"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.25 8.25C6.44347 8.25 7.58807 8.72411 8.43198 9.56802C9.27589 10.4119 9.75 11.5565 9.75 12.75C9.75 14.671 7.062 18.326 5.841 19.888C5.77085 19.9778 5.68118 20.0504 5.57877 20.1003C5.47637 20.1503 5.36393 20.1762 5.25 20.1762C5.13607 20.1762 5.02363 20.1503 4.92123 20.1003C4.81882 20.0504 4.72915 19.9778 4.659 19.888C3.438 18.327 0.75 14.671 0.75 12.75C0.75 11.5565 1.22411 10.4119 2.06802 9.56802C2.91193 8.72411 4.05653 8.25 5.25 8.25V8.25Z"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.25 23.25H14.25C14.6478 23.25 15.0294 23.092 15.3107 22.8107C15.592 22.5294 15.75 22.1478 15.75 21.75V17.25C15.75 16.8522 15.908 16.4706 16.1893 16.1893C16.4706 15.908 16.8522 15.75 17.25 15.75H20.25"
    ></path>
    <path
      stroke="inherit"
      d="M5.25 13.125C5.04289 13.125 4.875 12.9571 4.875 12.75C4.875 12.5429 5.04289 12.375 5.25 12.375"
    ></path>
    <path
      stroke="inherit"
      d="M5.25 13.125C5.45711 13.125 5.625 12.9571 5.625 12.75C5.625 12.5429 5.45711 12.375 5.25 12.375"
    ></path>
    <g>
      <path
        stroke="inherit"
        d="M19.5 4.875C19.2929 4.875 19.125 4.70711 19.125 4.5C19.125 4.29289 19.2929 4.125 19.5 4.125"
      ></path>
      <path
        stroke="inherit"
        d="M19.5 4.875C19.7071 4.875 19.875 4.70711 19.875 4.5C19.875 4.29289 19.7071 4.125 19.5 4.125"
      ></path>
    </g>
  </svg>
);
