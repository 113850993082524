import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';
import { ITEMS_TOP, ITEMS_BOTTOM } from './constants';
import { NavBarMobileProps } from './types';
import { NavBarMobileBar } from './styles';

export const NavBarMobile = (props: NavBarMobileProps) => {
  const [value, setValue] = React.useState(0);

  return (
    <>
      <NavBarMobileBar>
        <BottomNavigation
          showLabels
          color="secondary"
          value={value}
          onChange={(event, newValue) => {
            setValue((prev) => (prev === newValue ? 0 : newValue));
          }}
        >
          {ITEMS_TOP.map(
            ({ Icon, text, onClick, condition }) =>
              condition?.(props) && (
                <BottomNavigationAction
                  key={text}
                  label={text}
                  // @ts-ignore
                  icon={<Icon className="navbar-icon" {...props.iconProps} />}
                  onClick={() => onClick?.(props)}
                />
              ),
          )}

          {ITEMS_BOTTOM.map(
            ({ Icon, text, onClick, condition }) =>
              condition?.(props) && (
                <BottomNavigationAction
                  key={text}
                  label={text}
                  // @ts-ignore
                  icon={<Icon className="navbar-icon" {...props.iconProps} />}
                  onClick={() => onClick?.(props)}
                />
              ),
          )}
        </BottomNavigation>
      </NavBarMobileBar>
    </>
  );
};
