import { QueriesNames } from "./queriesNames";

export const queryFieldMap: Record<QueriesNames, string> = {
  [QueriesNames.mapPlaces]: 'places',
  [QueriesNames.nearbyPlaces]: 'places',
  [QueriesNames.selectedPlace]: 'place',
  [QueriesNames.userPlans]: 'userPlans',
  [QueriesNames.selectedUserPlan]: 'userPlan',
  [QueriesNames.updateUserPlan]: 'updateUserPlan',
  [QueriesNames.updatePlaceUserPlans]: 'updatePlaceUserPlans',
  [QueriesNames.addPlaceImageInit]: 'addPlaceImageInit',
  [QueriesNames.addFact]: 'addFact',
  [QueriesNames.addUserPlan]: 'addUserPlan',
  [QueriesNames.loginUser]: 'login',
  [QueriesNames.resetPassword]: 'resetPassword',
  [QueriesNames.forgotPassword]: 'forgotPassword',
  [QueriesNames.updatePassword]: 'updateUser',
  [QueriesNames.verifyUser]: 'verifyUser',
  [QueriesNames.createUser]: 'createUser',
  [QueriesNames.updateUser]: 'updateUser',
  [QueriesNames.logoutUser]: 'logout',
  [QueriesNames.removeUser]: 'removeUser',
};
