import { gql } from '@apollo/client';

export const ADD_FACT_MUTATION = gql`
  mutation AddFact($title: String!, $content: String!, $placeId: String!, $authorId: String!) {
    addFact(newFact: { placeId: $placeId, authorId: $authorId, title: $title, content: $content }) {
      statusCode
      code
      message
      data {
        id
        title
        content
        placeId
      }
    }
  }
`;
