import React, { useEffect, useState } from 'react';
import { AuthComponentProps } from './types';
import { useMutation } from '@apollo/client';
import { AUTH_USER_MUTATION } from './queries';
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from '../../utils/localStorage';

export const AuthProvider = ({ children }: AuthComponentProps) => {
  const authToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  const [isRequestFinished, setIsRequestFinished] = useState(false);
  const [authUserRequest, { called, loading }] = useMutation(AUTH_USER_MUTATION);

  useEffect(() => {
    if (authToken) {
      authUserRequest();
    } else {
      setIsRequestFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (called && !loading) setIsRequestFinished(true);
  }, [called, loading]);

  return isRequestFinished ? <>{children}</> : null;
};
