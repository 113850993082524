import { gql } from '@apollo/client';

export const ADD_TRACKING_MUTATION = gql`
  mutation AddTrackingEvent(
    $type: String!
    $url: String!
    $actionType: String
    $actionValue: String
    $isList: Boolean
    $listPosition: Float
    $listCount: Float
    $componentName: String
    $componentProps: String
  ) {
    addTrackingEvent(
      newEvent: {
        type: $type
        payload: {
          url: $url
          actionType: $actionType
          actionValue: $actionValue
          isList: $isList
          listPosition: $listPosition
          listCount: $listCount
          componentName: $componentName
          componentProps: $componentProps
        }
      }
    ) {
      data
      statusCode
      code
      message
    }
  }
`;
