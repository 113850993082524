import { DocumentNode } from "@apollo/client";
import {
  ADD_FACT_MUTATION,
  ADD_PLACE_IMAGE_INIT_MUTATION,
  ADD_USER_PLAN_MUTATION,
  CREATE_USER_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  GET_MAP_PLACES_QUERY,
  GET_NEARBY_PLACES_QUERY,
  GET_SELECTED_PLACE_QUERY,
  GET_SELECTED_USER_PLAN_QUERY,
  GET_USER_PLANS_QUERY,
  LOGIN_USER_MUTATION,
  LOGOUT_USER_MUTATION,
  REMOVE_USER_MUTATION,
  RESET_PASSWORD_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  UPDATE_PLACE_USER_PLANS_MUTATION,
  UPDATE_USER_MUTATION,
  UPDATE_USER_PLAN_MUTATION,
  VERIFY_USER_MUTATION,
} from "../queries";
import { QueriesNames } from "./queriesNames";

export const queryMap: Record<QueriesNames, DocumentNode> = {
  [QueriesNames.mapPlaces]: GET_MAP_PLACES_QUERY,
  [QueriesNames.nearbyPlaces]: GET_NEARBY_PLACES_QUERY,
  [QueriesNames.selectedPlace]: GET_SELECTED_PLACE_QUERY,
  [QueriesNames.userPlans]: GET_USER_PLANS_QUERY,
  [QueriesNames.selectedUserPlan]: GET_SELECTED_USER_PLAN_QUERY,
  [QueriesNames.updateUserPlan]: UPDATE_USER_PLAN_MUTATION,
  [QueriesNames.updatePlaceUserPlans]: UPDATE_PLACE_USER_PLANS_MUTATION,
  [QueriesNames.addPlaceImageInit]: ADD_PLACE_IMAGE_INIT_MUTATION,
  [QueriesNames.addFact]: ADD_FACT_MUTATION,
  [QueriesNames.addUserPlan]: ADD_USER_PLAN_MUTATION,
  [QueriesNames.loginUser]: LOGIN_USER_MUTATION,
  [QueriesNames.resetPassword]: RESET_PASSWORD_MUTATION,
  [QueriesNames.forgotPassword]: FORGOT_PASSWORD_MUTATION,
  [QueriesNames.verifyUser]: VERIFY_USER_MUTATION,
  [QueriesNames.createUser]: CREATE_USER_MUTATION,
  [QueriesNames.updateUser]: UPDATE_USER_MUTATION,
  [QueriesNames.logoutUser]: LOGOUT_USER_MUTATION,
  [QueriesNames.removeUser]: REMOVE_USER_MUTATION,
  [QueriesNames.updatePassword]: UPDATE_PASSWORD_MUTATION,
};
