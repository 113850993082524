import React, { useContext, useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Container, NavBarContent, NavBarHeader, StyledNavigationMenuIcon } from './styles';
import { LoginModalOpenContext } from '../../modals/loginModal';
import { NavBarOpenContext } from './navBarDrawerProvider';
import { RegisterModalOpenContext } from '../../modals/registerModal';
import { useAuth } from '../../hooks/useAuth';
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from '../../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { DYNAMIC_ITEMS_USER_PLANS, ITEMS_BOTTOM, ITEMS_TOP, SUB_NAV_BAR_TITLES } from './constants';
import { NavBarListItem } from './navBarListItem';
import { SubNavBar } from './subNavBar';
import { NavBarItem } from './types';
import { CreateUserPlanModalOpenContext } from '../../modals/createUserPlanModal';
import { NavBarMobile } from './NavBarMobile';
import { ApiDataContext, ApiRequestContext } from '../../api';

export const NavBarDrawer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [subContent, setSubContent] = useState<NavBarItem[]>([]);
  const [subContentTitle, setSubContentTitle] = useState<string>('');
  const { navbarIsOpen, toggleNavbarIsOpen, subNavbarIsOpen, toggleSubNavbarIsOpen } = useContext(NavBarOpenContext);
  const { toggleIsOpen: toggleCreateUserPlanModalOpen } = useContext(CreateUserPlanModalOpenContext);
  const { toggleIsOpen: toggleLoginModalOpen } = useContext(LoginModalOpenContext);
  const { toggleIsOpen: toggleRegisterModalOpen } = useContext(RegisterModalOpenContext);
  const { userPlans } = useContext(ApiDataContext);
  const { getUserPlans, logoutUser } = useContext(ApiRequestContext);
  const { isAuthorized } = useAuth();

  const userDataRaw = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);
  const userData = userDataRaw ? JSON.parse(userDataRaw) : {};

  useEffect(() => {
    if (isAuthorized) {
      getUserPlans.request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  useEffect(() => {
    if (subContentTitle === SUB_NAV_BAR_TITLES.PLANS && userPlans) {
      setSubContent(DYNAMIC_ITEMS_USER_PLANS({ toggleCreateUserPlanModalOpen, navigate, closeSubNavbar })(userPlans));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserPlans.response.loading, userPlans]);

  useEffect(() => {
    if (logoutUser.isFinished && logoutUser.data) {
      if (logoutUser.data?.statusCode === 200) {
        enqueueSnackbar('Successfully logged out');
      } else {
        enqueueSnackbar('Something went wrong');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutUser.isFinished, logoutUser.data]);

  const togglePlansOpen = () => {
    setSubContent(DYNAMIC_ITEMS_USER_PLANS({ toggleCreateUserPlanModalOpen, navigate, closeSubNavbar })(userPlans));
    toggleSubNavbarIsOpen();
    setSubContentTitle(SUB_NAV_BAR_TITLES.PLANS);
  };

  const closeSubNavbar = () => {
    toggleSubNavbarIsOpen(false);
  };

  const iconProps = {
    firstName: userData.firstName ?? '',
    lastName: userData.lastName ?? '',
  };

  const logoutRequest = () =>
    logoutUser.request({
      variables: { id: userData.id },
    });

  const commonItemProps = {
    toggleRegisterModalOpen,
    toggleLoginModalOpen,
    togglePlansOpen,
    closeSubNavbar,
    logoutRequest,
    isAuthorized,
    enqueueSnackbar,
    navigate,
    iconProps,
  };

  return (
    <>
      <Container open={navbarIsOpen} variant="permanent" onClose={() => toggleNavbarIsOpen(false)}>
        <NavBarHeader />
        <NavBarContent>
          <List>
            <ListItem disablePadding className="navbar-toggle">
              <ListItemButton onClick={() => toggleNavbarIsOpen()}>
                <ListItemIcon>
                  <StyledNavigationMenuIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            {ITEMS_TOP.map((item) => (
              <NavBarListItem key={item.text} {...item} {...commonItemProps} />
            ))}
          </List>
          <List>
            {ITEMS_BOTTOM.map((item) => (
              <NavBarListItem key={item.text} {...item} {...commonItemProps} />
            ))}
          </List>
        </NavBarContent>
      </Container>
      <SubNavBar
        mainNavBarIsOpen={navbarIsOpen}
        isOpen={subNavbarIsOpen}
        onClose={closeSubNavbar}
        title={subContentTitle}
      >
        {subContent.map((item) => (
          <NavBarListItem key={item.text} {...item} {...commonItemProps} />
        ))}
      </SubNavBar>
      <NavBarMobile {...commonItemProps} />
    </>
  );
};
