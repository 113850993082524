import { gql } from '@apollo/client';

export const GET_SELECTED_PLACE_QUERY = gql`
  query Place($placeId: ID!) {
    place(id: $placeId) {
      statusCode
      code
      message
      data {
        id
        name
        tags
        location {
          latitude
          longitude
          country
          city
          address
          administrativeArea1
          administrativeArea2
          administrativeArea3
          postalCode
        }
        facts {
          id
          title
          content
          placeId
          authorId
          verifiedAt
          updatedAt
        }
        images {
          link
          alt
        }
        ranking {
          overallScore
          dataScore
        }
      }
    }
  }
`;
