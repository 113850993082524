import { NavBarListItemProps } from './types';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

export const NavBarListItem = ({
  text,
  secondaryText,
  Icon,
  iconProps,
  onClick,
  condition,
  ...onClickProps
}: NavBarListItemProps) => {
  const actionProps = { iconProps, ...onClickProps };
  if (condition && !condition(actionProps)) return null;

  const handleOnClick = () => {
    if (onClick) onClick(actionProps);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleOnClick}>
        {Icon && (
          <ListItemIcon>
            <Icon {...iconProps} className="navbar-icon" />
          </ListItemIcon>
        )}
        <ListItemText primary={text} secondary={secondaryText} />
      </ListItemButton>
    </ListItem>
  );
};
