import { gql } from '@apollo/client';

export const GET_NEARBY_PLACES_QUERY = gql`
  query Places($tags: [String!], $lng: String!, $lat: String!, $limit: Float) {
    places(
      tags: $tags
      locationPoint: { latitude: $lat, longitude: $lng }
      sort: "location:asc"
      limit: $limit
    ) {
      statusCode
      code
      message
      data {
        id
        name
        location {
          latitude
          longitude
          distance
        }
        ranking {
          overallScore
          dataScore
        }
        images(limit: 4) {
          link
          thumbnailLink
          alt
        }
      }
    }
  }
`;
