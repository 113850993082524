import { QueriesNames } from "./queriesNames";
import { UseApiProps } from "../types";
import { queryMap } from "./queriesMap";
import { queryFieldMap } from "./queriesFields";

export const queryApiPropsMap: Record<QueriesNames, UseApiProps> = {
  [QueriesNames.mapPlaces]: {
    query: queryMap.mapPlaces,
    queryField: queryFieldMap.mapPlaces,
  },
  [QueriesNames.nearbyPlaces]: {
    query: queryMap.nearbyPlaces,
    queryField: queryFieldMap.nearbyPlaces,
  },
  [QueriesNames.selectedPlace]: {
    query: queryMap.selectedPlace,
    queryField: queryFieldMap.selectedPlace,
  },
  [QueriesNames.userPlans]: {
    query: queryMap.userPlans,
    queryField: queryFieldMap.userPlans,
  },
  [QueriesNames.selectedUserPlan]: {
    query: queryMap.selectedUserPlan,
    queryField: queryFieldMap.selectedUserPlan,
  },
  [QueriesNames.updateUserPlan]: {
    query: queryMap.updateUserPlan,
    queryField: queryFieldMap.updateUserPlan,
  },
  [QueriesNames.updatePlaceUserPlans]: {
    query: queryMap.updatePlaceUserPlans,
    queryField: queryFieldMap.updatePlaceUserPlans,
  },
  [QueriesNames.addPlaceImageInit]: {
    query: queryMap.addPlaceImageInit,
    queryField: queryFieldMap.addPlaceImageInit,
  },
  [QueriesNames.addFact]: {
    query: queryMap.addFact,
    queryField: queryFieldMap.addFact,
  },
  [QueriesNames.addUserPlan]: {
    query: queryMap.addUserPlan,
    queryField: queryFieldMap.addUserPlan,
  },
  [QueriesNames.loginUser]: {
    query: queryMap.loginUser,
    queryField: queryFieldMap.loginUser,
  },
  [QueriesNames.resetPassword]: {
    query: queryMap.resetPassword,
    queryField: queryFieldMap.resetPassword,
  },
  [QueriesNames.forgotPassword]: {
    query: queryMap.forgotPassword,
    queryField: queryFieldMap.forgotPassword,
  },
  [QueriesNames.verifyUser]: {
    query: queryMap.verifyUser,
    queryField: queryFieldMap.verifyUser,
  },
  [QueriesNames.createUser]: {
    query: queryMap.createUser,
    queryField: queryFieldMap.createUser,
  },
  [QueriesNames.updateUser]: {
    query: queryMap.updateUser,
    queryField: queryFieldMap.updateUser,
  },
  [QueriesNames.logoutUser]: {
    query: queryMap.logoutUser,
    queryField: queryFieldMap.logoutUser,
  },
  [QueriesNames.removeUser]: {
    query: queryMap.removeUser,
    queryField: queryFieldMap.removeUser,
  },
  [QueriesNames.updatePassword]: {
    query: queryMap.updatePassword,
    queryField: queryFieldMap.updatePassword,
  },
};
