import { gql } from '@apollo/client';

export const LOGIN_USER_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      statusCode
      code
      message
      data {
        user {
          id
          email
          firstName
          lastName
        }
        auth {
          authToken
          refreshToken
        }
      }
    }
  }
`;
