var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useApiMutation, useApiQuery } from "./useApi";
import { queryApiPropsMap } from "../constants/queryApiProps";
export var useApiProviderRequests = function (_a) {
    var setMapPlaces = _a.setMapPlaces, setNearbyPlaces = _a.setNearbyPlaces, setSelectedPlace = _a.setSelectedPlace, setUserPlans = _a.setUserPlans, setSelectedUserPlan = _a.setSelectedUserPlan, setLocationSuggestions = _a.setLocationSuggestions, setUserLocation = _a.setUserLocation, setPopularCityPlaces = _a.setPopularCityPlaces, setSearchPlaces = _a.setSearchPlaces, setPlacesList = _a.setPlacesList;
    var getMapPlaces = useApiQuery(__assign(__assign({}, queryApiPropsMap.mapPlaces), { onSuccess: function (data) { return setMapPlaces(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setMapPlaces([]); } }));
    var getSearchPlaces = useApiQuery(__assign(__assign({}, queryApiPropsMap.searchPlaces), { onSuccess: function (data) { return setSearchPlaces(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setSearchPlaces([]); } }));
    var getNearbyPlaces = useApiQuery(__assign(__assign({}, queryApiPropsMap.nearbyPlaces), { onSuccess: function (data) { return setNearbyPlaces(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setNearbyPlaces([]); } }));
    var getPlacesList = useApiQuery(__assign(__assign({}, queryApiPropsMap.placesByIds), { onSuccess: function (data) { return setPlacesList(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setPlacesList([]); } }));
    var getCityPopularPlaces = useApiQuery(__assign(__assign({}, queryApiPropsMap.popularCityPlaces), { onSuccess: function (data) { return setPopularCityPlaces(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setPopularCityPlaces({}); } }));
    var getSelectedPlace = useApiQuery(__assign(__assign({}, queryApiPropsMap.selectedPlace), { onSuccess: function (data) {
            var _a, _b, _c, _d;
            var formattedData = __assign({}, data === null || data === void 0 ? void 0 : data.data);
            if ((_b = (_a = formattedData === null || formattedData === void 0 ? void 0 : formattedData.location) === null || _a === void 0 ? void 0 : _a.geoJson) === null || _b === void 0 ? void 0 : _b.coordinates)
                formattedData.location.geoJson.coordinates = JSON.parse((_d = (_c = formattedData === null || formattedData === void 0 ? void 0 : formattedData.location) === null || _c === void 0 ? void 0 : _c.geoJson) === null || _d === void 0 ? void 0 : _d.coordinates);
            setSelectedPlace(formattedData);
        }, onClean: function () { return setSelectedPlace({}); } }));
    var getUserPlans = useApiQuery(__assign(__assign({}, queryApiPropsMap.userPlans), { onSuccess: function (data) { return setUserPlans(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setUserPlans([]); } }));
    var getSelectedUserPlan = useApiQuery(__assign(__assign({}, queryApiPropsMap.selectedUserPlan), { onSuccess: function (data) { return setSelectedUserPlan(data === null || data === void 0 ? void 0 : data.data); }, onClean: function () { return setSelectedUserPlan({}); } }));
    var getLocationSuggestions = useApiQuery(__assign(__assign({}, queryApiPropsMap.locationSuggestions), { onClean: function () { return setLocationSuggestions([]); }, onSuccess: function (data) {
            setLocationSuggestions(data === null || data === void 0 ? void 0 : data.data);
        } }));
    var getLocation = useApiQuery(__assign(__assign({}, queryApiPropsMap.location), { onClean: function () { return setUserLocation({}); }, onSuccess: function (data) {
            setUserLocation(data === null || data === void 0 ? void 0 : data.data);
        } }));
    var updateUserPlan = useApiMutation(queryApiPropsMap.updateUserPlan);
    var removeUserPlan = useApiMutation(queryApiPropsMap.removeUserPlan);
    var updatePlaceUserPlans = useApiMutation(queryApiPropsMap.updatePlaceUserPlans);
    var addPlaceImageInit = useApiMutation(queryApiPropsMap.updatePlaceUserPlans);
    var authUser = useApiMutation(queryApiPropsMap.authUser);
    var sendVerificationUser = useApiMutation(queryApiPropsMap.sendVerificationUser);
    var addFact = useApiMutation(queryApiPropsMap.addFact);
    var addUserPlan = useApiMutation(queryApiPropsMap.addUserPlan);
    var loginUser = useApiMutation(queryApiPropsMap.loginUser);
    var resetPassword = useApiMutation(queryApiPropsMap.resetPassword);
    var forgotPassword = useApiMutation(queryApiPropsMap.forgotPassword);
    var verifyUser = useApiMutation(queryApiPropsMap.verifyUser);
    var createUser = useApiMutation(queryApiPropsMap.createUser);
    var updateUser = useApiMutation(queryApiPropsMap.updateUser);
    var logoutUser = useApiMutation(queryApiPropsMap.logoutUser);
    var removeUser = useApiMutation(queryApiPropsMap.removeUser);
    var updatePassword = useApiMutation(queryApiPropsMap.updatePassword);
    var subscribeNewsletter = useApiMutation(queryApiPropsMap.subscribeNewsletter);
    return {
        getMapPlaces: getMapPlaces,
        getSearchPlaces: getSearchPlaces,
        getNearbyPlaces: getNearbyPlaces,
        getCityPopularPlaces: getCityPopularPlaces,
        getSelectedPlace: getSelectedPlace,
        getUserPlans: getUserPlans,
        getPlacesList: getPlacesList,
        getSelectedUserPlan: getSelectedUserPlan,
        updateUserPlan: updateUserPlan,
        removeUserPlan: removeUserPlan,
        updatePlaceUserPlans: updatePlaceUserPlans,
        addPlaceImageInit: addPlaceImageInit,
        addFact: addFact,
        addUserPlan: addUserPlan,
        loginUser: loginUser,
        resetPassword: resetPassword,
        forgotPassword: forgotPassword,
        verifyUser: verifyUser,
        createUser: createUser,
        updateUser: updateUser,
        logoutUser: logoutUser,
        removeUser: removeUser,
        updatePassword: updatePassword,
        getLocationSuggestions: getLocationSuggestions,
        subscribeNewsletter: subscribeNewsletter,
        authUser: authUser,
        sendVerificationUser: sendVerificationUser,
        getLocation: getLocation,
    };
};
