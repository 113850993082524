import { gql } from '@apollo/client';

export const ADD_PLACE_IMAGE_INIT_MUTATION = gql`
  mutation AddPlaceImageInit($placeId: String!, $alt: String, $description: String) {
    addPlaceImageInit(newImage: { placeId: $placeId, alt: $alt, description: $description }) {
      statusCode
      code
      message
      data {
        id
        signedUrl
      }
    }
  }
`;
